import {useState, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {useMediaQuery} from 'react-responsive';
import {useLocation} from 'react-router-dom';

import {mediaquerys} from '../../constant';
import SearchBox from '../SearchBox';

import UserContext from '../../UserContext';

import './style.css';

function Logo() {
  const host = window.location.host;
  const isTestnet = host !== 'mainnet.scan.caduceus.foundation' && host !== 'cmpscan.io'
  return (
      <a className="navbar-brand pt-md-0" href="/" target="_parent" aria-label="CaduceusInfo">
        <img id="logo-header" width="140" src="/images/logo.png" alt="Caduceus Logo" />
        {isTestnet && <span className='testFlag'>Testnet</span>}
      </a>
  );
}

function ToggleNavigation({expanded, onClick}) {
  return (
    <button
      type="button"
      onClick={onClick}
      className="navbar-toggler btn u-hamburger"
      aria-label="Toggle navigation"
      aria-expanded={String(expanded)}
      aria-controls="navBar"
      data-toggle="collapse"
      data-target="#navBar"
    >
      <span id="hamburgerTrigger" className="u-hamburger__box">
        <span className="u-hamburger__inner"></span>
      </span>
    </button>
  );
}

function Menu({title, path, user, active, minWidth = '230px', className = '', aClassName = '', ulClassName = '', children}) {
  const isMinMD = useMediaQuery({query: mediaquerys.md});
  const [hover, setHover] = useState(false);

  const hasChildren = Array.isArray(children) && children.length;

  const handleClick = e => {
    if (!path) {
      e.preventDefault();
    }
  };

  const events = {};

  if (!isMinMD) {
    events.onClick = () => setHover(!hover);
  } else {
    events.onMouseEnter = () => setHover(true);
    events.onMouseLeave = () => setHover(false);
  }

  return (
    <li {...events} className={`nav-item ${hasChildren ? 'hs-has-sub-menu' : ''} u-header__nav-item ${hover ? 'hs-sub-menu-opened' : ''} ${active ? 'active' : ''} ${className}`}>
      <a
        onClick={handleClick}
        className={`nav-link u-header__nav-link ${hasChildren ? 'u-header__nav-link-toggle' : ''} ${aClassName}`}
        href={path || '#'}
        aria-haspopup="true"
        aria-expanded={String(hover)}
        aria-labelledby="pagesSubMenu"
      >
        {typeof title === 'function' ? title(user) : title}
      </a>
      {hasChildren ? (
        <ul
          id="blockchainSubMenu"
          className={`hs-sub-menu u-header__sub-menu u-header__sub-menu--spacer animated ${hover ? 'slideInUp' : 'fadeOut'} ${ulClassName}`}
          aria-labelledby="blockchainMegaMenu"
          style={{minWidth: minWidth, display: hover ? 'block' : 'none'}}
        >
          {children.map((c, i) => {
            if (c.type === 'divider') {
              return <li key={i} className={`dropdown-divider ${c.className}`}></li>;
            }

            return (
              <li key={i} className={c.className || ''}>
                {c.component ? (
                  c.component()
                ) : (
                  <a href={c.path} className="nav-link u-header__sub-menu-nav-link">
                    {typeof c.title === 'function' ? c.title() : c.title}
                  </a>
                )}
              </li>
            );
          })}
        </ul>
      ) : undefined}
    </li>
  );
}

export default function Header() {
  const userContext = useContext(UserContext);
  const location = useLocation();
  const isMinMD = useMediaQuery({query: mediaquerys.md});
  const {t} = useTranslation(['common']);
  const [expandNavBar, setExpandNavBar] = useState(false);
  const isHome = location.pathname === '/';
  const user = userContext.user || {};

  console.log('userContext', user.user_name);

  const [state] = useState({
    navs: {
      home: {
        title: t('common.nav.home'),
        path: '/',
        children: [],
      },
      blockchain: {
        title: 'Blockchain',
        children: [
          {
            title: 'Top Accounts',
            path: '/accounts',
          },
          {
            type: 'divider',
          },
          {
            title: 'View Txns',
            path: '/txs',
          },
          // {
          //   title: 'View Pending Txns',
          //   path: '/txsPending',
          // },
          {
            title: 'View Contract Internal Txns',
            path: '/txsInternal',
          },
          {
            type: 'divider',
          },
          {
            title: 'View Blocks',
            path: '/blocks',
          },
          // {
          //   title: 'Forked Blocks (Reorgs)',
          //   path: '/blocks_forked',
          // },
          {
            type: 'divider',
          },
          {
            title: 'Verified Contracts',
            path: '/contractsVerified',
          },
        ],
      },
      // validators: {
      //   title: 'Validators',
      //   children: [
      //     {
      //       title: 'Validators Leaderboard',
      //       path: '/validators',
      //     },
      //     {
      //       title: 'View Validators Set Info',
      //       path: '/validatorset',
      //     },
      //   ],
      // },
      tokens: {
        title: 'Tokens',
        children: [
          // {
          //   title: 'CRC-20 Tokens By MarketCap',
          //   path: '/tokens',
          // },
          {
            title: 'CRC-20 Tokens By Volume',
            path: '/tokens-volume',
          },
          {
            title: 'View CRC-20 Transfers',
            path: '/tokentxns',
          },
          {type: 'divider'},
          {
            title: 'CRC-721 Top Tokens',
            path: '/tokens-nft',
          },
          {
            title: 'View CRC-721 Transfers',
            path: '/tokentxns-nft',
          },
        ],
      },
      resources: {
        title: 'Resources',
        children: [
          {
            title: 'Charts & Stats',
            path: '/charts',
          },
          // {
          //   title: 'Top Statistics',
          //   path: '/topstat',
          // },
          // {type: 'divider'},
          // {
          //   title: 'Yield Farms List',
          //   path: '/yieldfarms',
          // },
        ],
      },
      misc: {
        title: 'Misc',
        minWidth: '200px',
        children: [
          // {
          //   title: 'APIs',
          //   path: '/miscapis',
          // },
          {
            title: 'Verify Contract',
            path: '/verifyContract',
          },
          // {
          //   title: 'Byte to Opcode',
          //   path: '/opcode-tool',
          // },
          // {
          //   title: 'Broadcast TXN',
          //   path: '/pushTx',
          // },
          // {
          //   title: 'Vyper Online Compiler',
          //   path: '/vyper',
          // },
          // {
          //   title: () => {
          //     return (
          //       <>
          //         Token Approvals{' '}
          //         <sup>
          //           <span className="badge badge-secondary ml-1"> Beta</span>
          //         </sup>
          //       </>
          //     );
          //   },
          //   path: '/tokenapprovalchecker',
          // },
          {type: 'divider'},
          // {
          //   title: 'Pending Tx Query',
          //   path: '/pending',
          // },
          {
            title: 'Gas Price',
            path: '/gasprice',
          },
        ],
      },
      explorers: {
        title: () => {
          return (
            <>
              <span className="btn btn-sm btn-icon btn-soft-primary cursor-pointer d-none d-md-inline-block">
                {/* <img className="u-xs-avatar btn-icon__inner" src="/images/ht_small.png?v=1.4" alt="Caduceus" /> */}
              </span>
              <span className="d-inline-block d-md-none">Explorers</span>
            </>
          );
        },
        minWidth: '140px',
        children: [
          {
            title: 'Caduceus Mainnet',
            path: 'https://caduceusinfo.com',
          },
          {
            title: 'Caduceus Testnet',
            path: 'https://testnet.caduceusinfo.com',
          },
        ],
      },
    },
    // signIn: {
    //   path: '/login',
    //   title: () => {
    //     return (
    //       <>
    //         <i className="fa fa-user-circle mr-1"></i>Sign In
    //       </>
    //     );
    //   },
    //   minWidth: '190px',
    // },
    account: {
      title: u => {
        return (
          <>
            <i className="fa fa-user-circle mr-1"></i>
            {u.user_name}
          </>
        );
      },
      minWidth: '190px',
      children: [
        {
          title: 'My Profile',
          path: '/myaccount',
        },
        {type: 'divider'},
        {
          title: 'Watch List',
          path: '/myaddress',
        },
        {
          title: 'Txn Private Notes',
          path: '/mynotes_tx',
        },
        {
          title: 'Address Private Notes',
          path: '/mynotes_address',
        },
        {
          title: 'Token Ignore List',
          path: '/mytokenignore',
        },
        {type: 'divider'},
        {
          title: 'API Keys',
          path: '/myapikey',
        },
        {
          title: 'Custom ABI',
          path: '/mycustomabi',
        },
        {type: 'divider', className: 'mb-3'},
        {
          className: 'px-3',
          component: () => {
            return (
              <a className="btn btn-xs btn-block btn-soft-primary" href="/login?cmd=logout">
                Sign Out
              </a>
            );
          },
        },
      ],
    },
  });

  const handleToggleNavigation = () => {
    setExpandNavBar(!expandNavBar);
  };

  return (
    <header className="u-header" id="Header">
      <div className={`u-header__section ${isHome ? 'py-1' : ''}`}>
        <div className="container">
          <nav className={`js-mega-menu navbar navbar-expand-md u-header__navbar u-header__navbar--no-space hs-menu-initialized hs-menu-horizontal ${!isMinMD ? 'hs-mobile-state' : ''}`}>
            <div className="w-lg-auto">
              <Logo />
              {!isHome ? (
                <div id="ethPrice" style={{visibility: 'hidden'}}>
                  <div className="d-none d-md-inline-block u-label u-label--price rounded mt-1 ml-n1 text-nowrap">
                    <span className="text-dark">CMP: $13.02</span>
                    <span data-toggle="tooltip" data-placement="top" data-title="Changes in the last 24 hours" data-original-title="" title="">
                      <span className="text-danger"> (-1.44%)</span>
                    </span>
                  </div>
                </div>
              ) : undefined}
            </div>
            <div>
              <ToggleNavigation onClick={handleToggleNavigation} expanded={expandNavBar} />
            </div>
            <div className="d-flex flex-column w-100">
              <div className={`collapse navbar-collapse u-header__navbar-collapse order-md-2 ${expandNavBar ? 'show' : 'dd'}`}>
                <ul className="navbar-nav u-header__navbar-nav px-2 px-md-0">
                  <Menu {...state.navs.home} />
                  <Menu {...state.navs.blockchain} />
                  {/* <Menu {...state.navs.validators} /> */}
                  <Menu {...state.navs.tokens} />
                  <Menu {...state.navs.resources} />
                  <Menu {...state.navs.misc} />
                  <li className="u-header__nav-separator"></li>
                  {/* {user.user_id ? <Menu {...state.account} user={user} ulClassName="hs-position-right hs-reversed" /> : <Menu {...state.signIn} ulClassName="hs-position-right hs-reversed" />} */}
                  {/* <li className="u-header__nav-separator"></li> */}
                  {/* <Menu {...state.navs.explorers} className="my-md-n1" aClassName="u-header__nav-link-toggle--mobile pr-0" ulClassName="dropdown-menu-md-right" /> */}
                </ul>
              </div>
              {!isHome ? (
                <div id="form1">
                  <SearchBox className="w-100 w-lg-55 order-md-1 ml-md-auto mt-2 mb-2 mb-md-0" />
                </div>
              ) : undefined}
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
}

import {useState} from 'react';
import {useRequest} from 'ahooks';

import VerifyCode from '../../components/VerifyCode';

export default function Register() {
  const registerRequest = useRequest(
    body => ({
      url: '/blockBrowser/user/login/signUp',
      method: 'post',
      body: JSON.stringify(body),
    }),
    {manual: true, formatResult: r => r},
  );
  const [form, setForm] = useState({
    user_name: '',
    email: '',
    password: '',
    confirm_password: '',
    captcha_id: '',
    verify_code: '',

    terms: false,
    newsletter: false,
  });

  const [errors, setErrors] = useState({});

  const handleForm = field => e => {
    setForm({...form, [field]: e.target.value});

    if (e.target.value) {
      setErrors({...errors, [field]: undefined});
    }
  };

  const handleUpdateVerifyCode = e => {
    setForm({...form, ...e});

    if (e.verify_code) {
      setErrors({...errors, verify_code: undefined});
    }
  };

  const handleSubmit = () => {
    const emailREG = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/gi;
    const _errors = {};

    Object.keys(form)
      .filter(f => f !== 'newsletter')
      .forEach(field => {
        _errors[field] = !form[field];
      });

    if (form.password && form.password.length < 5) {
      _errors.password = true;
    }

    if (form.password && form.confirm_password && form.password !== form.confirm_password) {
      _errors.confirm_password = 'equalto';
    }

    if (!emailREG.test(form.email)) {
      _errors.email = true;
    }

    if (!_errors.user_name && (form.user_name.length < 5 || form.user_name.length > 30)) {
      _errors.user_name = true;
    }

    if (Object.keys(_errors).some(e => !!_errors[e])) {
      setErrors(_errors);
    } else {
      registerRequest.run({...form, terms: undefined, newsletter: undefined}).then(res => {
        if (res.code === 0) {
          window.location.href = '/login';
        }
      });
    }

    console.log(_errors);
  };
  const handleBlur = key => () => {
    const emailREG = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/gi;
    const _errors = {...errors};

    Object.keys(form)
      .filter(f => f !== 'newsletter')
      .forEach(field => {
        if (field === key) {
          _errors[field] = !form[field];
        }
      });

    if (form.password && form.password.length < 5 && key === 'password') {
      _errors.password = true;
    }

    if (form.password && form.confirm_password && form.password !== form.confirm_password && key === 'confirm_password') {
      _errors.confirm_password = 'equalto';
    }

    if (!emailREG.test(form.email) && key === 'email') {
      _errors.email = true;
    }

    if (!_errors.user_name && (form.user_name.length < 5 || form.user_name.length > 30) && key === 'user_name') {
      _errors.user_name = true;
    }

    if (Object.keys(_errors).some(e => !!_errors[e])) {
      if (_errors[key]) {
        setErrors(_errors);
      }
    }
  };

  const showError = typeof registerRequest.data?.code !== 'undefined' && registerRequest.data?.code !== 0;

  return (
    <main className="bg-light content-height" role="main">
      <div className="container space-2">
        <form className="js-validate w-md-75 w-lg-50 mx-md-auto" noValidate="novalidate">
          <div className="mb-7">
            <h1 className="h3 text-primary font-weight-normal mb-2">
              Register a <span className="font-weight-semi-bold">New</span> Account
            </h1>
            <p>Fill out the form to get started.</p>
          </div>
          <div className="alert alert-danger" style={{display: showError ? 'block' : 'none'}}>
            {registerRequest.data?.enMsg}
          </div>
          <div>
            <div className={`js-form-message form-group ${errors.user_name ? 'u-has-error' : ''}`}>
              <label className="d-block" htmlFor="txtUserName">
                Username
              </label>
              <input
                type="text"
                maxLength="30"
                className="form-control form-control-sm"
                placeholder="Username has to be from 5 to 30 characters in length, only alphanumeric characters allowed."
                aria-label="Username"
                data-rule-minlength="5"
                data-msg="Username is invalid."
                value={form.user_name}
                onChange={handleForm('user_name')}
                onBlur={handleBlur('user_name')}
              />
              <div className="invalid-feedback" style={{display: errors.user_name ? 'block' : 'none'}}>
                Username is invalid.
              </div>
            </div>
            <div className={`js-form-message form-group ${errors.email ? 'u-has-error' : ''}`}>
              <label className="d-block" htmlFor="txtEmail">
                Email Address
              </label>
              <input
                maxLength="100"
                className="form-control form-control-sm"
                type="email"
                placeholder="A confirmation code will be sent to this address"
                aria-label="Email address"
                data-msg="Please enter a valid email address."
                value={form.email}
                onChange={handleForm('email')}
                onBlur={handleBlur('email')}
              />
              <div className="invalid-feedback" style={{display: errors.email ? 'block' : 'none'}}>
                Please enter a valid email address.
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className={`js-form-message form-group ${errors.password ? 'u-has-error' : ''}`}>
                  <label className="d-block" htmlFor="txtPassword">
                    Password
                  </label>
                  <input
                    type="password"
                    maxLength="75"
                    className="form-control form-control-sm"
                    placeholder="******"
                    aria-label="********"
                    data-rule-minlength="5"
                    data-msg="Your password must be at least 5 characters long."
                    value={form.password}
                    onChange={handleForm('password')}
                    onBlur={handleBlur('password')}
                  />
                  <div className="invalid-feedback" style={{display: errors.password ? 'block' : 'none'}}>
                    Your password must be at least 5 characters long.
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className={`js-form-message form-group ${errors.confirm_password ? 'u-has-error' : ''}`}>
                  <label className="d-block" htmlFor="txtPassword2">
                    Confirm Password
                  </label>
                  <input
                    type="password"
                    maxLength="75"
                    className="form-control form-control-sm"
                    placeholder="******"
                    aria-label="********"
                    data-rule-minlength="5"
                    value={form.confirm_password}
                    onChange={handleForm('confirm_password')}
                    onBlur={handleBlur('confirm_password')}
                  />
                  <div className="invalid-feedback" style={{display: errors.confirm_password ? 'block' : 'none'}}>
                    {errors.confirm_password === 'equalto' ? 'Password does not match, please check again.' : 'Your password must be at least 5 characters long.'}
                  </div>
                </div>
              </div>
            </div>

            <div className={`js-form-message form-group ${errors.verify_code ? 'u-has-error' : ''}`}>
              <label className="d-block" htmlFor="txtEmail">
                Captcha
              </label>
              <VerifyCode value={form.verify_code} onChange={handleUpdateVerifyCode} />
              <div className="invalid-feedback" style={{display: errors.verify_code ? 'block' : 'none'}}>
                Captcha is invalid.
              </div>
            </div>
            <div className={`js-form-message ${errors.terms ? 'u-has-error' : ''}`}>
              <div
                className="custom-control custom-checkbox d-flex align-items-center text-muted"
                onClick={e => {
                  handleForm('terms')({target: {value: !form.terms}});
                }}
              >
                <input
                  type="checkbox"
                  name="ContentPlaceHolder1_MyCheckBox"
                  className="custom-control-input"
                  data-msg="Please accept our Terms and Conditions."
                  checked={form.terms ? 'checked' : ''}
                  onChange={() => {}}
                />
                <label className="custom-control-label active" htmlFor="ContentPlaceHolder1_MyCheckBox">
                  <span>
                    <span>I agree to the </span>
                    <a className="link-muted" href="/terms" target="_blank">
                      Terms and Conditions
                    </a>
                  </span>
                </label>
              </div>
              <div className="invalid-feedback" style={{display: errors.terms ? 'block' : 'none'}}>
                Please accept our Terms and Conditions.
              </div>
            </div>
            <div className="js-form-message">
              <div className="custom-control custom-checkbox d-flex align-items-center text-muted" onClick={() => handleForm('newsletter')({target: {value: !form.newsletter}})}>
                <input type="checkbox" className="custom-control-input" name="ContentPlaceHolder1_SubscribeNewsletter" checked={form.newsletter ? 'checked' : ''} onChange={() => {}} />
                <label className="custom-control-label active" htmlFor="ContentPlaceHolder1_SubscribeNewsletter">
                  <span>I agree to receive the CaduceusInfo newsletter and understand that I can unsubscribe at any time.</span>
                </label>
              </div>
            </div>
            <div className="d-flex justify-content-center my-5"></div>
            <div className="row align-items-center">
              <div className="col-5 col-sm-6">
                <span className="text-muted">Already have an account?</span>
                <a className="text-nowrap" href="/login">
                  Click to Sign In
                </a>
              </div>
              <div className="col-7 col-sm-6 text-right">
                <input type="button" value="Create an Account" className="btn btn-sm btn-primary" onClick={handleSubmit} disabled={registerRequest.loading} />
              </div>
            </div>
          </div>
        </form>
      </div>
    </main>
  );
}

import {useContext} from 'react';
import MySideBar from '../../components/MySideBar';
import UserContext from '../../UserContext';

export default function MyNotesAddress() {
  const userContext = useContext(UserContext);
  const user = userContext.user || {};

  if (!user.token) {
    return (window.location.href = '/login');
  }

  return (
    <main role="main">
      <div className="container space-1">
        <div className="row">
          <MySideBar value="mynotes_address" />
          <div className="col-md-9">
            <div className="card">
              <div className="card-header d-md-flex justify-content-between align-items-center">
                <div className="d-flex flex-wrap">
                  <div className="mr-2 mb-2 mb-md-0">
                    <h2 className="card-header-title mt-1">My Address Private Notes</h2>
                  </div>
                  <div className="mr-2 mb-2 mb-md-0">
                    <a
                      href="#"
                      id="ContentPlaceHolder1_addnew"
                      data-toggle="modal"
                      data-target="#responsive"
                      rel="tooltip"
                      title=""
                      className="btn btn-xss btn-primary rounded"
                      onclick="addNewNote();"
                      data-original-title="Add a new address private note"
                    >
                      <i className="fa fa-plus-square fa-lg mr-1" style={{marginLeft: '-3px', marginBottom: '3px'}}></i>Add
                    </a>
                  </div>
                </div>
                <div className="d-flex ml-auto">
                  <div className="position-relative order-1 order-md-2">
                    <a
                      id="searchFilterInvoker"
                      className="btn btn-sm btn-icon btn-primary"
                      href="javascript:;"
                      role="button"
                      aria-controls="searchFilter"
                      aria-haspopup="true"
                      aria-expanded="false"
                      data-unfold-target="#searchFilter"
                      data-unfold-type="css-animation"
                      data-unfold-duration="300"
                      data-unfold-delay="300"
                      data-unfold-hide-on-scroll="false"
                      data-unfold-animation-in="slideInUp"
                      data-unfold-animation-out="fadeOut"
                    >
                      <i className="fa fa-search btn-icon__inner"></i>
                    </a>
                    <div
                      id="searchFilter"
                      className="dropdown-menu dropdown-unfold dropdown-menu-sm-right p-2 u-unfold--css-animation u-unfold--hidden"
                      aria-labelledby="searchFilterInvoker"
                      style={{width: '265px', animationDuration: '300ms'}}
                    >
                      <form action="/mynotes_address" method="get" className="js-focus-state input-group input-group-sm w-100" autocomplete="off">
                        <input id="q" name="q" type="search" value="" className="form-control" placeholder="Search Address Private Tags" maxlength="50" />
                        <div className="input-group-append">
                          <button type="submit" className="btn btn-primary" data-toggle="tooltip" title="" data-original-title="Search Address Private Tags">
                            Find
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <span id="spanResult"></span>
                <div className="alert alert-info" id="success-alert" style={{display: 'none'}}>
                  <button type="button" className="close" data-dismiss="alert">
                    x
                  </button>
                  <span id="actionResult"></span>
                </div>
                <div className="d-md-flex justify-content-between align-items-center mb-4">
                  <p className="mb-2 mb-lg-0">
                    <i id="spinwheel" className="fa fa-spin fa-spinner fa-1x fa-pulse" style={{display: 'none', marginTop: '4px'}}>
                      &nbsp;
                    </i>
                    &nbsp;0 used (Out of 500 max quota)
                  </p>
                  <nav className="mb-4 mb-md-0" aria-label="page navigation">
                    <ul className="pagination pagination-sm mb-0">
                      <li className="page-item disabled">
                        <span className="page-link">First</span>
                      </li>
                      <li className="page-item disabled">
                        <span className="page-link">
                          <i className="fa fa-chevron-left small"></i>
                        </span>
                        <span className="sr-only">Previous</span>
                      </li>
                      <li className="page-item disabled">
                        <span className="page-link text-nowrap">
                          Page <strong className="font-weight-medium">1</strong> of <strong className="font-weight-medium">1</strong>
                        </span>
                      </li>
                      <li className="page-item disabled">
                        <span className="page-link">
                          <i className="fa fa-chevron-right small"></i>
                        </span>
                        <span className="sr-only">Previous</span>
                      </li>
                      <li className="page-item disabled">
                        <span className="page-link">Last</span>
                      </li>
                    </ul>
                  </nav>
                </div>
                <div className="table-responsive mb-2 mb-md-0">
                  <div className="mx-md-auto">
                    <figure id="SVGdataReport1" className="mb-7 ie-data-report">
                      <table className="table">
                        <thead className="thead-light">
                          <tr>
                            <th
                              width="1"
                              scope="col"
                              data-container="body"
                              data-toggle="popover"
                              data-placement="top"
                              data-original-title=""
                              title=""
                              data-content="Mark your favorite address to the top of the list"
                            >
                              <i className="fal fa-question-circle text-secondary mr-1"></i>
                            </th>
                            <th scope="col">Action</th>
                            <th scope="col">Name Tag</th>
                            <th scope="col">Address Note</th>
                            <th scope="col" data-toggle="tooltip" title="" data-original-title="UTC TimeZone (yyyy-mm-dd)">
                              Created
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td colSpan="5" className="align-top">
                              <center>
                                <div className="alert alert-secondary mt-4" role="alert">
                                  <i className="fal fa-info-circle"></i> <span className="text-dark">There are no private tags found</span>
                                </div>
                              </center>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default function Footer() {
  const addNetwork = async () => {
    const chainId = await window.ethereum.request({ method: 'eth_chainId' });
    window.$.ajax({
      url: '/network_params.json',
      method: 'GET',
    }).done(function (res) {
      if(res.chainId === chainId) alert(res.chainName + ' has already been added to Metamask.')
      window.ethereum.request({
        method: 'wallet_addEthereumChain', // Metamask api name
        params: [res]
      }).then(() => console.log('Success'))
      .catch((error) => console.log("Error", error.message));
    })
  }
  return (
    <footer id="Footer" className="bg-img-hero-footer bg-dark py-4 customBgColor">
      <div className="container">
        <div className="row justify-content-sm-between align-items-center">
          <div className="col-sm-6 mb-3 mb-sm-0">
            <div className="d-flex align-items-center">
              {/* <img width="16" src="/images/svg/brands/huobi-light.svg?v=1.3" alt="Caduceus Logo" /> */}
              <span className="h5 text-white mb-0 ml-3">Powered by Vertex Lab</span>
            </div>
          </div>

          <div className="col-sm-6 align-self-bottom">
            <div className="d-flex justify-content-sm-end align-items-center">
              {/* <button type="button" className="btn btn-xss btn-soft-light text-nowrap d-flex align-items-center mr-2" onClick={()=>addNetwork()}>
                <img className="mr-1" width="15" src="/images/svg/brands/metamask.svg" alt="Metamask" /> Add CMP Network
              </button> */}
              {/* <a className="btn btn-xss btn-soft-light mr-2" href="/settings">
                <i className="fa fa-cogs mr-1"></i>Preferences
              </a>
              <button id="darkModaBtn" type="button" data-toggle="tooltip" data-title="Day/Night Mode" className="btn btn-sm btn-icon btn-soft-light" data-original-title="" title="">
                <i id="darkModaBtnIcon" className="fa fa-moon"></i>
              </button> */}
            </div>
          </div>
        </div>
        <hr className="opacity-md" />
        <div className="row justify-content-between align-items-center font-size-1">
          <div className="col-md-6 mb-2 mb-md-0 d-flex">
            <p className="mb-0 text-white">
              Caduceus Foundation © 2022
            </p>
          </div>
          <div className="col-md-6 text-md-right">
            <ul className="list-inline mb-0">
              <li className="list-inline-item">
                {/* <a className="unordered-list-text" rel="nofollow noopener" target="_blank" href="http://caduceusinfo.freshstatus.io/">
                  Network Status
                </a> */}
              </li>
              <li className="list-inline-item">
                {/* <a className="btn btn-sm btn-icon btn-soft-light btn-pill" href="/contactus" data-toggle="tooltip" data-placement="top" title="" data-original-title="Contact Us">
                  <i className="far fa-envelope btn-icon__inner"></i>
                </a> */}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}

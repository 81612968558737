import {useState, useContext, useEffect} from 'react';
import {useRequest} from 'ahooks';
import Modal from 'react-bootstrap/Modal';

import MySideBar from '../../components/MySideBar';
import UserContext from '../../UserContext';
import Pager from '../../components/Pager';
import React from 'react';

function CreateApiKeyModal({user, show, onHide}) {
  const [fields, setFields] = useState({
    api_key_name: '',
  });
  const [error, setError] = useState();
  const keyCreateRequest = useRequest(
    body => ({
      url: '/blockBrowser/user/apiKey/keyCreate',
      method: 'post',
      headers: {
        Authorization: user.token,
      },
      body: JSON.stringify(body),
    }),
    {manual: true, formatResult: r => r},
  );

  const handleAddApiKey = () => {
    keyCreateRequest.run(fields).then(res => {
      console.log(res);
      if (res.code !== 0) {
        setError(res.enMsg);
      } else {
        onHide();
      }
    });
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton className="p-2">
        <Modal.Title>Create a new API-KEY token</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group row">
          <div className="alert alert-danger" style={{display: error ? 'block' : 'none', width: '100%'}}>
            {error}
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-3" htmlFor="AppName">
            AppName :
          </label>
          <div className="input-group input-group-sm col-sm-9">
            <input
              type="text"
              maxLength="30"
              className="form-control"
              placeholder="Optional"
              value={fields.api_key_name}
              onChange={e => {
                setFields({...fields, api_key_name: e.target.value});
              }}
            />
            <p></p>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button data-dismiss="modal" className="btn btn-sm btn-secondary btn-xs" type="button" causesvalidation="False" onClick={onHide}>
          Cancel
        </button>
        <input type="button" value="Continue" className="btn btn-sm btn-primary btn-xs" onClick={handleAddApiKey} />
      </Modal.Footer>
    </Modal>
  );
}

function EditApiKeyModal({data = {}, user, show, onHide}) {
  const [fields, setFields] = useState({
    api_key_id: data.api_key_id || '',
    api_key_name: data.api_key_name || '',
  });

  const keyEditRequest = useRequest(
    body => ({
      url: '/blockBrowser/user/apiKey/keyEdit',
      method: 'post',
      headers: {
        Authorization: user.token,
      },
      body: JSON.stringify(body),
    }),
    {manual: true, formatResult: r => r},
  );

  const keyDeleteRequest = useRequest(
    body => ({
      url: '/blockBrowser/user/apiKey/keyDelete',
      method: 'post',
      headers: {
        Authorization: user.token,
      },
      body: JSON.stringify(body),
    }),
    {manual: true, formatResult: r => r},
  );
  useEffect(() => {
    setTimeout(() => {
      setFields({
        api_key_id: data.api_key_id,
        api_key_name: data.api_key_name,
      });
    }, 100);
  }, [data]);

  const handleEdit = () => {
    if (!fields.api_key_name) {
      return;
    }

    keyEditRequest.run(fields).then(res => {
      if (res.code === 0) {
        // onHide();
      } else {
      }
    });
  };

  const showError = typeof keyEditRequest.data?.code !== 'undefined' && keyEditRequest.data?.code !== 0;
  const showSuccess = typeof keyEditRequest.data?.code !== 'undefined' && keyEditRequest.data?.code === 0;

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton className="p-2">
        <Modal.Title>APIKey Options</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form method="post" className="mx-md-auto">
          <div className="alert alert-danger" style={{display: showError ? 'block' : 'none'}}>
            {keyEditRequest.data?.enMsg}
          </div>
          <div class="alert alert-success" style={{display: showSuccess ? 'block' : 'none'}}>
            <strong>Status:</strong> Successfully Updated APIKey <strong>{data.api_key_token}</strong> options
          </div>

          <div className="form-group row">
            <label className="col-sm-3" htmlFor="Address">
              APIKey
            </label>
            <div className="col-sm-9">
              <strong>{data.api_key_token}</strong>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-3" htmlFor="Description">
              AppName
            </label>
            <div className="input-group input-group-sm col-sm-9">
              <input
                type="text"
                value={fields.api_key_name}
                className="form-control"
                onChange={e => {
                  setFields({...fields, api_key_name: e.target.value});
                }}
              />
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-sm-flex justify-content-between" style={{width: '100%'}}>
          <div className="d-flex">
            <div>
              <input type="button" value="Save Changes" className="btn btn-primary btn-xs" onClick={handleEdit} />
            </div>
            <div className="px-2">
              <input type="button" value="Return" className="btn btn-secondary btn-xs" onClick={onHide} />
            </div>
          </div>
          <div className="ml-auto">
            <a
              href="#"
              className="btn btn-danger btn-xs"
              onClick={e => {
                e.preventDefault();
                keyDeleteRequest.run({...fields, api_key_name: undefined}).then(res => {
                  if (res.code === 0) {
                    alert('Remove Success');
                  } else {
                    alert(res.enMsg);
                  }
                });
              }}
            >
              <i className="far fa-trash-alt" data-toggle="tooltip" title="" data-original-title="Unlink Address from Watch List"></i>&nbsp; Remove
            </a>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

function ApiKeyList({user}) {
  const [currentApiKey, setCurrentApiKey] = useState();
  const [showEditModal, setShowEditModal] = useState(false);

  const [state, setState] = useState({
    body: {
      start: '1',
      length: '50',
      // field: 'key_name',
      // value: '',
    },
  });

  const listApiKeyRequest = useRequest(
    body => ({
      url: '/blockBrowser/user/apiKey/keyList',
      method: 'post',
      headers: {
        Authorization: user.token,
      },
      body: JSON.stringify(body),
    }),
    {manual: true},
  );

  useEffect(() => {
    listApiKeyRequest.run(state.body);
  }, []);

  const handleEditHide = () => {
    listApiKeyRequest.run(state.body);
    setShowEditModal(false);
  };

  const data = listApiKeyRequest.data?.api_key_list || [];
  const counts = listApiKeyRequest.data?.counts || 0;
  const limit_counts = listApiKeyRequest.data?.limit_counts || 0;
  const totalPage = Math.ceil(Number(counts) / state.body.length);

  return (
    <div className="card-body">
      <div className="d-md-flex justify-content-between align-items-center mb-4">
        <p className="mb-2 mb-lg-0">
          {counts} used (Out of {limit_counts} max quota)
        </p>
        <nav className="mb-4 mb-md-0" aria-label="page navigation">
          <div>
            <Pager current={state.body.start} total={totalPage} path="/txs" />
          </div>
        </nav>
      </div>
      <div className="table-responsive mb-2 mb-md-0">
        <div className="mx-md-auto">
          <figure className="mb-7 ie-data-report">
            <table className="table">
              <thead className="thead-light">
                <tr>
                  <th scope="col">Action</th>
                  <th scope="col">Api-Key Token</th>
                  <th scope="col" data-toggle="tooltip" title="" data-original-title="UTC TimeZone (yyyy-mm-dd)">
                    Created
                  </th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(data) && data.length !== 0 ? (
                  data.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td className="align-top text-nowrap">
                          <a
                            className="btn btn-outline-secondary btn-xss mr-1"
                            href="#"
                            data-toggle="tooltip"
                            title=""
                            data-original-title="Modify API Key"
                            onClick={e => {
                              e.preventDefault();
                              setCurrentApiKey(item);
                              setShowEditModal(true);
                            }}
                          >
                            Edit
                          </a>
                        </td>
                        <td className="align-top">
                          {item.api_key_token}
                          <span className="d-block small">AppName: {item.api_key_name}</span>
                        </td>
                        <td className="align-top">{item.create_time}</td>
                      </tr>
                    );
                  })
                ) : listApiKeyRequest.loading ? (
                  <tr />
                ) : (
                  <tr>
                    <td colSpan="3" className="align-top">
                      <center>
                        <div className="alert alert-secondary mt-4" role="alert">
                          <i className="fal fa-info-circle"></i> <span className="text-dark">You have yet to create an Api-Key Token.</span>
                        </div>
                      </center>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </figure>
        </div>
      </div>
      <EditApiKeyModal user={user} data={currentApiKey} show={showEditModal} onHide={handleEditHide} />
    </div>
  );
}

export default function MyApiKey() {
  const userContext = useContext(UserContext);
  const user = userContext.user || {};
  const [show, setShow] = useState(false);
  const [showList, setShowList] = useState(true);

  if (!user.token) {
    return (window.location.href = '/login');
  }

  const handleCreateHide = () => {
    setShowList(false);
    setTimeout(() => {
      setShowList(true);
    }, 10);
    setShow(false);
  };

  return (
    <main role="main">
      <div className="container space-1">
        <div className="row">
          <MySideBar value="myapikey" />
          <div className="col-md-9">
            <div className="card">
              <div className="card-header d-md-flex justify-content-between align-items-center">
                <div className="d-flex flex-wrap">
                  <div className="mr-2 mb-2 mb-md-0">
                    <h2 className="card-header-title mt-1">My API Keys</h2>
                  </div>
                  <div className="mr-2 mb-2 mb-md-0">
                    <div className="position-relative order-1 order-md-2">
                      <a
                        href="#"
                        id="ContentPlaceHolder1_addnew"
                        data-toggle="modal"
                        data-target="#myModal"
                        rel="tooltip"
                        title=""
                        className="btn btn-xss btn-primary rounded"
                        data-original-title="Create a new API-KEY token"
                        onClick={() => {
                          setShow(!show);
                        }}
                      >
                        <i className="fa fa-plus-square fa-lg mr-1" style={{marginLeft: '-3px', marginBottom: '3px'}}></i>Add
                      </a>
                    </div>
                  </div>
                </div>
                <div className="d-flex ml-auto"></div>
              </div>
              {showList ? <ApiKeyList user={user} /> : undefined}
            </div>
          </div>
        </div>
      </div>
      <CreateApiKeyModal user={user} show={show} onHide={handleCreateHide} />
    </main>
  );
}

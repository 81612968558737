import {useRequest} from 'ahooks';
import {useLocation, useParams} from 'react-router-dom';
import { useState, useRef} from 'react';
import {Metamask} from 'ethpay.core';

import Loading from '../../components/Loading';

import CardTabsHeader from './CardTabsHeader';
import Header from './Header';
import Holders from './Holders';
import Overview from './Overview';
import ProfileSummary from './ProfileSummary';
import Transactions from './Transactions';
import TokenInfo from './TokenInfo';
import TokenExchange from './TokenExchange';
import Inventory from './Inventory';
import ReadContract from './ReadContract';
import WriteContract from './WriteContract';
import TokenAnalytics from './TokenAnalytics';
import Copy from '../../components/Copy';

let web3 = undefined;

export default function Token() {
  const {token} = useParams();
  const location = useLocation();
  const [currentTab, setCurrentTab] = useState(location.hash.slice(1) || 'transactions'); // transactions, tokentxns, contracts, events, uncle, analytics
  const [currentContract, setCurrentContract] = useState();
  const [currentAccount, setCurrentAccount] = useState();

  const tokenDetailRequest = useRequest({
    url: '/blockBrowser/tokens/address/tokenDetail',
    method: 'post',
    body: JSON.stringify({
      field: 'address',
      value: token,
    }),
  });
  const contractQueryByAddressRequest = useRequest({
    url: '/blockBrowser/contract/contractQueryByAddress',
    method: 'post',
    body: JSON.stringify({
      contract_address: token,
    }),
  });


  const qs=require('qs')
  const query = qs.parse(location.search, {ignoreQueryPrefix: true});
  let account = query.a ;

  const a=window.location.href
  const href1=a.substring(a.indexOf('=')+1)

  const tokenAccountBalanceRequest = useRequest({
    url: '/blockBrowser/tokens/address/tokenAccountBalance',
    method: 'post',
    body: JSON.stringify({
      token_address: token,
      account_address: account,
      token_types: 0
    }),
  });

  const tokenIDDetailRequest = useRequest({
    url: '/blockBrowser/tokens/address/tokenIDHolder',
    method: 'post',
    body: JSON.stringify({
      "token_address": "0x114d818074a00c221a5d5a16611709527f7ed4ee",
      "token_id": "1"
    }),
  });


  if (tokenIDDetailRequest.loading ||tokenDetailRequest.loading || contractQueryByAddressRequest.loading || tokenAccountBalanceRequest.loading) {
    return <Loading />;
  }
  const overview = tokenDetailRequest.data?.overview || {};

  const profile_summary = tokenDetailRequest.data?.profile_summary || {};

  const detail = contractQueryByAddressRequest.data?.results || {};
  
  const balance = tokenAccountBalanceRequest.data || '0';


  const tokenIDHolder=tokenIDDetailRequest.data || '0';

  const handleConnect = async e => {
    e.preventDefault();

    if (!web3) {
      const provider = await Metamask.getProvider();

      web3 = new window.Web3(provider);
      const pool = new web3.eth.Contract(JSON.parse(detail.Abi), token);

      Metamask.requestAccounts().then(result => {
        setCurrentAccount(Array.isArray(result) ? result[0] : undefined);
      });

      setCurrentContract(pool);
    }
  };

  const tabsConst = [
    {
      key: 'transactions',
      title: 'Transfers',
    },
    account === undefined ? {
      key: 'balances',
      title:'Holders',
    }:{
      key:'1'
    },
    account === undefined && overview.token_types !== 1 ?{
      key: 'inventory',
      title:'Inventory'
    }:{
      key:'1'
    },
    account === undefined ? {
      key: 'readContract',
      title:'Read Contract'
    }:{
      key:'1'
    },
    account === undefined ? {
      key: 'writeContract',
      title:'Write Contract'
    }:{
      key:'1'
    },
    // account === undefined ? {
    //   key: 'tokenAnalytics',
    //   title:'Analytics'
    // }:{
    //   key:'1'
    // }
  ];
  return (
    <main role="main">
      <Header data={overview} />
      <div className="container space-bottom-2">
        <div className="row mb-4">
          <div className="col-md-6 mb-3 mb-md-0">
            <Overview data={overview} tokenId={tokenIDHolder}/>
          </div>
          <div className="col-md-6">
            <ProfileSummary data={profile_summary} />
          </div>
        </div>
        {
          
            // account != undefined && overview.token_types === 1 ? (
              account != undefined  && href1.length >='42'  ? (
              <div id="ContentPlaceHolder1_filteredByAddress" className="card mb-1">
                <div className="card-body">
                    <div className="row mx-gutters-md-1">
                        <div id="ContentPlaceHolder1_divFilteredHolderAddress" className="col-md-4 col-lg">
                        <h6 className="small font-weight-bold text-dark mb-1">
                            <i className="fa fa-address-book mr-1 text-warning"></i>
                            <span className="text-uppercase">Filtered by Token Holder</span> 
                        </h6>
                        <div className="d-flex clipboard-hover">
                            <a 
                                id="spanTarget_0xa770322e4ccd67ae0acc5e6bc505b6055dd08078" 
                                className="font-weight-bold text-truncate d-block mr-2" 
                                // href="/address/0xa770322e4ccd67ae0acc5e6bc505b6055dd08078" 
                                href={`/address/${account}`}
                                data-toggle="tooltip" 
                                title="" 
                                data-original-title="View Address Page"> {account || ""} 
                                </a>
                                <Copy text={account}  title="Copy Txn Hash to clipboard" />
                        </div>
                        <hr className="d-block d-md-none my-2" />
                        </div>
                        <div id="ContentPlaceHolder1_divFilteredHolderBalance" className="col-md u-ver-divider u-ver-divider--left u-ver-divider--none-md">
                        <h6 className="small text-uppercase text-secondary mb-1">Balance</h6>
                        {balance} {overview.token_symbol}
                        </div>
                    </div>
                </div>
            </div>
            ):undefined
         } 

        <div className="card">
          <CardTabsHeader
            data={profile_summary}
            tabs={tabsConst}
            current={currentTab}
            onChange={e => {
              setCurrentTab(e);
            }}
          />
          <div className="card-body">
            <div className="tab-content">
              {currentTab === 'transactions' ? <Transactions token={token} overview={overview} a={account} /> : undefined }
              {currentTab === 'balances' ? <Holders token={token} overview={overview} profile_summary={profile_summary} /> : undefined}
              {currentTab === 'tokenInfo' ? <TokenInfo token={token} overview={overview} profile_summary={profile_summary} /> : undefined}
              {currentTab === 'tokenExchange' ? <TokenExchange token={token} overview={overview} profile_summary={profile_summary} /> : undefined}
              {currentTab === 'inventory' ? <Inventory token={token} overview={overview} profile_summary={profile_summary} /> : undefined}
              {currentTab === 'readContract' ? (
                <ReadContract account={currentAccount} contract={currentContract} onConnect={handleConnect} token={token} overview={overview} profile_summary={profile_summary} />
              ) : undefined}
              {currentTab === 'writeContract' ? (
                <WriteContract account={currentAccount} contract={currentContract} onConnect={handleConnect} token={token} overview={overview} profile_summary={profile_summary} />
              ) : undefined}
              {currentTab === 'tokenAnalytics' ? <TokenAnalytics token={token} overview={overview} profile_summary={profile_summary} /> : undefined}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

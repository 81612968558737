import Copy from '../../components/Copy';

export default function ProfileSummary({data}) {
  return (
    <div className="card h-100">
      <div className="card-header d-flex justify-content-between align-items-center">
        <div className="d-flex d-none">
          <h2 className="card-header-title mr-1 mb-0 d-flex mb-1">Profile Summary</h2>
        </div>
        <div className="d-flex d-none">
          <div className="ml-auto">
            <a className="btn btn-xs btn-icon btn-soft-primary mr-1" href="#" data-toggle="tooltip" data-title="Reputation NEUTRAL" data-original-title="" title="">
              <span className="fas fa-meh btn-icon__inner"></span>
            </a>
          </div>
          <div className="ml-auto">
            <a
              className="btn btn-xs btn-icon btn-soft-info ml-1 mr-1"
              href="#"
              target="_blank"
              data-toggle="tooltip"
              data-placement="top"
              data-title="Blue Check Mark - Click for more info"
              data-original-title=""
              title=""
            >
              <i className="fa fa-check btn-icon__inner"></i>
            </a>
          </div>
          <div className="position-relative">
            <a
              className="btn btn-xs btn-icon btn-soft-secondary"
              href="#"
              role="button"
              aria-controls="dropdownTools-1"
              aria-haspopup="true"
              aria-expanded="false"
              data-unfold-event="click"
              data-unfold-target="#dropdownTools-1"
              data-unfold-type="css-animation"
              data-unfold-duration="300"
              data-unfold-delay="300"
              data-unfold-hide-on-scroll="false"
              data-unfold-animation-in="slideInUp"
              data-unfold-animation-out="fadeOut"
            >
              <i className="fa fa-ellipsis-v btn-icon__inner"></i>
            </a>
            <div
              className="dropdown-menu dropdown-menu-right dropdown-unfold u-unfold--css-animation u-unfold--hidden fadeOut"
              aria-labelledby="dropdownToolsInvoker-1"
              style={{animationDuration: '300ms', right: 0}}
            >
              <a className="dropdown-item" href={`/tokencheck-tool?t=${data.token_address}`}>
                <i className="fa fa-fw fa-history mr-1"></i>Check previous token supply
              </a>
              <a className="dropdown-item" href="#">
                <i className="fas fa-fw fa-wallet mr-1"></i>Add Token to Web3 Wallet
              </a>
              <hr className="my-1" />
              <a className="dropdown-item" href="https://docs.google.com/forms/d/e/1FAIpQLSelQDTtXyfNoqV-MRx7F1ZfQg38-jA319QJtPullIgFFg-IvA/viewform" rel="nofollow noopener" target="_blank">
                <i className="fa fa-fw fa-pencil-alt mr-1"></i>Update Token Info
              </a>
              <a className="dropdown-item" href={`/contactus?id=5&a=${data.token_address}`}>
                <i className="fa fa-fw fa-user-tag mr-1"></i>Update Name Tag
              </a>
              <a className="dropdown-item" href={`/contactus?id=5&a=${data.token_address}`}>
                <i className="fa fa-fw fa-tags mr-1"></i>Submit Label
              </a>
              <a className="dropdown-item" href="/contactus?id=6">
                <i className="fa fa-fw fa-flag mr-1"></i>Report/Flag Address
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="card-body">
        <div className="row align-items-center">
          <div className="col-md-4 mb-1 mb-md-0">Contract:</div>
          <div className="col-md-8">
            <div className="d-flex clipboard-hover">
              <a className="text-truncate d-block mr-2" href={`/address/${data.token_address}`}>
                {data.token_address}
              </a>
              <Copy text={data.token_address} title="" />
            </div>
          </div>
        </div>

        <hr className="hr-space" />

        <div>
          <div className="row align-items-center">
            <div className="col-md-4 mb-1 mb-md-0">Decimals:</div>
            <div className="col-md-8">{data.decimals}</div>
          </div>
          <hr className="hr-space" />
        </div>

        <div>
          <div className="row align-items-center">
            <div className="col-md-4 mb-1 mb-md-0">Official Site:</div>
            <div className="col-md-8">
              <a href={data.official_site} rel="nofollow" target="_blank">
                {data.official_site} <i className="fa fa-external-link-alt small ml-1"></i>
              </a>
            </div>
          </div>

          <hr className="hr-space" />
        </div>

        <div>
          <div className="row align-items-center">
            <div className="col-md-4 mb-1 mb-md-0">Social Profiles:</div>
            <div className="col-md-8">
              {/* <ul className="list-inline mb-0">
                <li className="list-inline-item mr-3">
                  <a
                    className="link-hover-secondary"
                    data-placement="top"
                    data-toggle="tooltip"
                    data-original-title="CoinMarketCap: holo"
                    target="_blank"
                    href="https://coinmarketcap.com/currencies/holo/"
                  >
                    <img src="/images/cmc.svg" className="mt-n3px" width="13" />
                  </a>
                </li>
                <li className="list-inline-item mr-3">
                  <a className="link-hover-secondary" data-placement="top" data-toggle="tooltip" data-original-title="CoinGecko: holo" target="_blank" href="https://www.coingecko.com/en/coins/holo/">
                    <img src="/images/coingecko.svg" className="mt-n3px" width="13" />
                  </a>
                </li>
              </ul> */}
            </div>
            <hr className="hr-space" />
          </div>
        </div>
      </div>
    </div>
  );
}

import { useState, useRef } from 'react';
import { useRequest } from 'ahooks';

export default function SearchBox({ className }) {
  const [state, setState] = useState({
    f: '0',
    inputValue:'',
    off:true,
    value:'All Filters'
  });

  const searchInputRef = useRef();
  const retrievalRequest = useRequest(
    body => ({
      url: '/blockBrowser/index/retrieval',
      method: 'post',
      body: JSON.stringify(body),
    }),
    { manual: true },
  );
  const data=retrievalRequest.data||[];

  const handleSelect = e => {
    setState({
       f: e.target.value ,
    });
  };
  const handleInput = e => {
    setState({
      inputValue:e.target.value,
      off:true,
    })
    const q = searchInputRef.current.value.trim();
    if (!q) {
      return;
    }

    if (String(Number(q)) === q) {
      return;
    }

    if (q.length === 66 && q.indexOf('0x') === 0) {
      return;
    }

    if (q.length === 42 && q.indexOf('0x') === 0) {
      return;
    }
    if(q.length>=2){
      retrievalRequest.run({field:'token',value:e.target.value}).then(res=>{
        // console.log(res,'res')
      })
    }else{
      data.length=0
    }
  }

  const handleSearchText = (e) => {

    e.preventDefault();
    const q = searchInputRef.current.value.trim();
    if (!q) {
      return;
    }

    if (String(Number(q)) === q) {
      window.location.href = `/block/${q}`;
      return;
    }

    if (q.length === 66 && q.indexOf('0x') === 0) {
      window.location.href = `/tx/${q}`;
      return;
    }

    if (q.length === 42 && q.indexOf('0x') === 0) {
      window.location.href = `/address/${q}?p=1`;
      return;
    }

    retrievalRequest.run({ field: 'address', value: q }).then(res => {
      console.log(res, 'res');
      if (res.types === 0) {
        window.location.href = `/search?f=0&q=${q}`;
      } else if (res.types === 1) {
        // 通证
        window.location.href = `/token/${q}`;
      } else if (res.types === 2) {
        // 地址
        window.location.href = `/address/${q}?p=1`;
      }
    });
    retrievalRequest.run({field:'token',value:inputValue}).then(res=>{
      if(data==""){
        window.location.href = `/search?f=0&q=${inputValue}`;
      }
    })
  };
  const {inputValue,value}=state
  const show=()=>{
    setState({
      off:true,
    })
  }
  window.onclick=function(){
    setState({
      off:false
    })
  }
  return (
    <form className={`mb-3 ${className}`} method="GET" onSubmit={handleSearchText} style={{ position: 'relative'}}>
      <div className="input-group input-group-shadow">
        <div className="input-group-prepend d-none d-md-block" >
          <select name="f" defaultValue={value} onChange={handleSelect} className="custom-select custom-arrow-select input-group-text font-size-base filterby">
            <option value="0">All Filters</option>
            <option value="1">Addresses</option>
            <option value="2">Tokens</option>
            <option value="3">Name Tags</option>
            {/* <option value="4">Labels</option> */}
            {/* <option value="5">Websites</option> */}
          </select>
        </div>
        <input
          ref={searchInputRef}
          onChange={handleInput}
          type="text"
          className="form-control searchautocomplete ui-autocomplete-input list-unstyled py-3 mb-0"
          placeholder="Search by Address / Txn Hash / Block / Token"
          aria-describedby="button-header-search"
          autoComplete="off"
          value={inputValue}
          style={{position:'relative'}}
          onClick={show}
        />
        <input type="hidden" value="" id="hdnSearchText" />
        <input id="hdnIsTestNet" value="False" type="hidden" />
        <div className="input-group-append">
          <button className="btn btn-primary" type="submit" onClick={handleSearchText}>
            <i className="fa fa-search"></i>
          </button>
        </div>
      </div>
      <ul id="ui-id-2" 
          tabIndex="0" 
          className="ui-menu ui-widget ui-widget-content ui-autocomplete ui-front list-unstyled bg-white-content p-2 mb-0"
        style={{
          // top: '45px', 
          left:'17%', 
          width: '76%', 
          position:'absolute',
          display: data.length!=0  && state.off==true && searchInputRef.current.value.trim().length!=0 && searchInputRef.current.value.trim().length>=2 ?  'block' :'none'}}>
          {
            data.map((item,index)=>{
                return (
                  <div key={index} className='div'>
                   {
                     item.Data.length > 0 &&
                      <li className="mb-2 ether-search-heading ui-menu-item" key={item.tag}>
                        <h5 className="h6 font-weight-bold bg-light px-2 py-1 rounded mb-2 ui-menu-item-wrapper">
                          {item.tag}
                        </h5>
                      </li>
                    }
                    {
                      item.Data.map((mapItem,index1)=>{
                        return (
                          <li className="mb-2 ui-menu-item" key={index1}>
                            <a className="ether-search media rounded p-2 ui-menu-item-wrapper" href={`/token/${mapItem.token_address}`} id="ui-id-3" tabIndex="-1" >
                              <div className="mr-2">
                                <img src={mapItem.token_logo != '' ? `${mapItem.token_logo}`:'/images/main/empty-token.png' } className="u-xs-avatar" style={{marginTop: '1px'}}/>
                              </div>
                              <div className="media-body text-truncate">
                                <h6 className="d-flex align-items-center text-size-1 mb-0">
                                  <div className="text-truncate mr-2">{mapItem.token_name}({mapItem.token_symbol})</div>
                                  <span className="badge bg-soft-secondary mr-2">{mapItem.token_price}</span>
                                  <i className="fa fa-badge-check text-info ml-auto"></i>
                                </h6>
                                <span className="text-secondary text-truncate font-size-1">{mapItem.token_address}</span>
                                {/* <div className="text-secondary font-size-1 text-truncate">https://www.stcoins.com/</div> */}
                              </div>
                            </a>
                          </li>
                        )
                      })
                    }
                </div>
                  )
            })
          }
      </ul>

    </form>
  );
}

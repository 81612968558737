import {useState, useEffect} from 'react';
import {useRequest} from 'ahooks';
import {Metamask} from 'ethpay.core';

import Loading from '../../components/Loading';

import Code from './Contracts.Components/Code';
import Read from './Contracts.Components/Read';
import Write from './Contracts.Components/Write';

import Toast from 'react-bootstrap/Toast';

const hideCodeAddress = [
  '0xff0abf31af4bb1b71c025d32509420892df8011b',
  '0xf66a0b0c862d23d4be5bde9c59328460753fffb4',
  '0x871fcb6b836db1b5d6ee64901fb17245cd403e6d',
  '0x3502518c63990da9e3822a60a01a1e3c96cad661'
]

let web3 = undefined;
window.Metamask = Metamask;
const ethereum = window.ethereum;
export default function Contracts({address, overview}) {
  const [currentTab, setCurrentTab] = useState('code');
  const [currentContract, setCurrentContract] = useState();
  const [currentAccount, setCurrentAccount] = useState();
  const [host, setHost] = useState(false)
  const [show, setShow] = useState(false)
  const contractQueryByAddressRequest = useRequest({
    url: '/blockBrowser/contract/contractQueryByAddress',
    method: 'post',
    body: JSON.stringify({
      contract_address: address,
    }),
  });

  const checksumAddress = window.Web3.utils.toChecksumAddress(address);
  const handleChangeTab = tab => e => {
    e.preventDefault();
    setCurrentTab(tab);
  };

  useEffect(()=>{
    setHost(window.location.host)
    hideCodeAddress.includes(address.toLowerCase()) && setCurrentTab('read');
  }, address)

  if (contractQueryByAddressRequest.loading) {
    return <Loading />;
  }

  const detail = contractQueryByAddressRequest.data?.results || {};

  const handleConnect = async e => {
    e.preventDefault();

    if (!web3) {
      const host = window.location.host;
      const chainId = await ethereum.request({ method: 'eth_chainId' });
      const isMainnetShow = host === 'mainnet.scan.caduceus.foundation' && Number(chainId) !== 256256
      const isGalaxyShow = host !== 'mainnet.scan.caduceus.foundation' && Number(chainId) !== 512512
      if(isMainnetShow || isGalaxyShow){
        setShow(true)
        return false
      }
      const provider = await Metamask.getProvider();
      console.log('provider', provider);
      web3 = new window.Web3(provider);
      const pool = new web3.eth.Contract(JSON.parse(detail.Abi), checksumAddress);

      Metamask.requestAccounts().then(result => {
        setCurrentAccount(Array.isArray(result) ? result[0] : undefined);
      });

      setCurrentContract(pool);
    }
  };

  // 验证状态 0-未验证 1-验证通过 2-验证失败
  if (String(detail.IsVerfied) === '0') {
    return (
      <div className="tab-pane fade active show" style={{display: 'visible'}}>
        <span className="mb-3">
          <span className="h5 mb-0">
            <i className="fa fa-info-circle text-secondary"></i>
          </span>
          <span> Are you the contract creator? </span>
          <a href={`/verifyContract?a=${address}`}>
            <b>Verify and Publish</b>
          </a>{' '}
          your contract source code today!
          <br />
          {/* <span className="h6 mb-0">
            This contract was created by the contract code at <a href="/address/0x00000000687f5b66638856396bee28c1db0178d1">0x00000000687f5b66638856396bee28c1db0178d1</a>
          </span>
          <br />
          Note: We also found another <a href={`/find-similar-contracts?a=${address}&lvl=5`}>6321181 contracts </a> with exact matching byte codes
          <br /> */}
          {/* <br /> */}
        </span>
        <a
          id="btnConvert2"
          className="btn btn-warning btn-xss mb-1 mr-1"
          href={`/bytecode-decompiler?a=${address}`}
          data-toggle="tooltip"
          title=""
          target="_blank"
          data-original-title="Opens up the Runtime ByteCode Decompiler page"
        >
          Decompile ByteCode <i className="fa fa-external-link-alt pos-top-n1 small ml-1"></i>
        </a>
        <button id="ContentPlaceHolder1_btnconvert222" className="btn btn-warning btn-xss mb-1 mr-1" type="button">
          Switch to Opcodes View
        </button>
        <button
          id="ContentPlaceHolder1_btnFindSimiliarContracts"
          className="btn btn-secondary btn-xss mb-1"
          type="button"
          onClick={e => {
            window.location.href = `/find-similar-contracts?a=${checksumAddress}&lvl=5'`;
          }}
          data-toggle="tooltip"
          title=""
          data-toggle-second="tooltip"
          data-original-title="Find other contracts with Similar Contract Codes using a 'Fuzzy' Search Algorithm"
        >
          Similar Contracts
        </button>
        <div id="dividcode">
          <pre className="wordwrap" style={{height: '15pc'}}>
            {detail.ContractCode}
          </pre>
        </div>
      </div>
    );
  }

  if (String(detail.IsVerfied) === '2') {
    return (
      <div className="tab-pane fade active show" id="contracts" style={{minHeight: '500px'}}>
        验证失败
      </div>
    );
  }

  return (
    <div className="tab-pane fade active show" id="contracts" style={{minHeight: '500px'}}>
      <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide>
        <Toast.Header>
          <strong className="me-auto">Connect Tips:</strong>
        </Toast.Header>
        <Toast.Body>Only support chain: {host === 'mainnet.scan.caduceus.foundation' ? 256256 : 512512}</Toast.Body>
      </Toast>
      <ul className="nav nav-pills nav-pills-secondary mb-3" id="nav_subtabs" role="tablist">
        {!hideCodeAddress.includes(address.toLowerCase()) &&<li className="nav-item">
          <a className={`nav-link ${currentTab === 'code' ? 'show active' : ''}`} href="#code" data-toggle="tab" onClick={handleChangeTab('code')}>
            <span>Code</span>
          </a>
        </li>}
        <li className="nav-item">
          <a className={`nav-link ${currentTab === 'read' ? 'show active' : ''}`} href="#readContract" data-toggle="tab" onClick={handleChangeTab('read')}>
            Read <span className="d-none d-sm-inline-block">Contract</span>
          </a>
        </li>
        <li className="nav-item">
          <a className={`nav-link ${currentTab === 'write' ? 'show active' : ''}`} href="#writeContract" data-toggle="tab" onClick={handleChangeTab('write')}>
            Write <span className="d-none d-sm-inline-block">Contract</span>
          </a>
        </li>
      </ul>
      {currentTab === 'code' && !hideCodeAddress.includes(address.toLowerCase()) ? <Code address={address} overview={overview} detail={detail} /> : undefined}
      {currentTab === 'read' ? <Read address={address} overview={overview} account={currentAccount} contract={currentContract} onConnect={handleConnect} /> : undefined}
      {currentTab === 'write' ? <Write address={address} overview={overview} account={currentAccount} contract={currentContract} onConnect={handleConnect} /> : undefined}
    </div>
  );
}

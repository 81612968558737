import {useContext} from 'react';
import MySideBar from '../../components/MySideBar';
import UserContext from '../../UserContext';

export default function MyNotesTx() {
  const userContext = useContext(UserContext);
  const user = userContext.user || {};

  if (!user.token) {
    return (window.location.href = '/login');
  }

  return (
    <main role="main">
      <div className="container space-1">
        <div className="row">
          <MySideBar value="mynotes_tx" />
          <div className="col-md-9">
            <div className="card">
              <div className="card-header d-md-flex justify-content-between align-items-center">
                <div className="d-flex flex-wrap">
                  <div className="mr-2 mb-2 mb-md-0">
                    <h2 className="card-header-title mt-1">My Transactions Private Notes</h2>
                  </div>
                  <div className="mr-2 mb-2 mb-md-0">
                    <a
                      href="#"
                      id="ContentPlaceHolder1_addnew"
                      data-toggle="modal"
                      data-target="#responsive"
                      rel="tooltip"
                      title=""
                      className="btn btn-xss btn-primary rounded"
                      onclick="addNewNote();"
                      data-original-title="Add a new transaction private note"
                    >
                      <i className="fa fa-plus-square fa-lg mr-1" style={{marginLeft: '-3px', marginBottom: '3px'}}></i>Add
                    </a>
                  </div>
                </div>
                <div className="d-flex ml-auto">
                  <div className="position-relative order-1 order-md-2">
                    <a
                      id="searchFilterInvoker"
                      className="btn btn-sm btn-icon btn-primary"
                      href="javascript:;"
                      role="button"
                      aria-controls="searchFilter"
                      aria-haspopup="true"
                      aria-expanded="false"
                      data-unfold-target="#searchFilter"
                      data-unfold-type="css-animation"
                      data-unfold-duration="300"
                      data-unfold-delay="300"
                      data-unfold-hide-on-scroll="false"
                      data-unfold-animation-in="slideInUp"
                      data-unfold-animation-out="fadeOut"
                    >
                      <i className="fa fa-search btn-icon__inner"></i>
                    </a>
                    <div
                      id="searchFilter"
                      className="dropdown-menu dropdown-unfold dropdown-menu-sm-right p-2 u-unfold--css-animation u-unfold--hidden fadeOut"
                      aria-labelledby="searchFilterInvoker"
                      style={{width: '265px', animationDuration: '300ms'}}
                    >
                      <form action="/mynotes_tx" method="get" className="js-focus-state input-group input-group-sm w-100" autocomplete="off">
                        <input id="q" name="q" type="search" value="" className="form-control" placeholder="Search Txn Private Notes" />
                        <div className="input-group-append">
                          <button type="submit" className="btn btn-primary" data-toggle="tooltip" title="" data-original-title="Search Txn Private Notes">
                            Find
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <span id="spanResult"></span>
                <div className="d-md-flex justify-content-between align-items-center mb-4">
                  <p className="mb-2 mb-lg-0">
                    <i id="spinwheel" className="fa fa-spin fa-spinner fa-1x fa-pulse" style={{display: 'none', marginTop: '4px', marginRight: '4px'}}>
                      &nbsp;
                    </i>
                    &nbsp;2 used (Out of 1000 max quota)
                  </p>
                  <nav className="mb-4 mb-md-0" aria-label="page navigation">
                    <ul className="pagination pagination-sm mb-0">
                      <li className="page-item disabled">
                        <span className="page-link">First</span>
                      </li>
                      <li className="page-item disabled">
                        <span className="page-link">
                          <i className="fa fa-chevron-left small"></i>
                        </span>
                        <span className="sr-only">Previous</span>
                      </li>
                      <li className="page-item disabled">
                        <span className="page-link text-nowrap">
                          Page <strong className="font-weight-medium">1</strong> of <strong className="font-weight-medium">1</strong>
                        </span>
                      </li>
                      <li className="page-item disabled">
                        <span className="page-link">
                          <i className="fa fa-chevron-right small"></i>
                        </span>
                        <span className="sr-only">Previous</span>
                      </li>
                      <li className="page-item disabled">
                        <span className="page-link">Last</span>
                      </li>
                    </ul>
                  </nav>
                </div>
                <div className="table-responsive mb-2 mb-md-0">
                  <div className="mx-md-auto">
                    <figure id="SVGdataReport1" className="mb-7 ie-data-report">
                      <table className="table">
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">Action</th>
                            <th scope="col">Transaction Private Note</th>
                            <th scope="col" data-toggle="tooltip" title="" data-original-title="UTC TimeZone (yyyy-mm-dd)">
                              Created
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="align-top">
                              <a
                                data-toggle="modal"
                                data-target="#responsive"
                                className="btn btn-outline-secondary btn-xss html-link-custom mr-1"
                                rel="tooltip"
                                title=""
                                onclick="javascript:getnote('0x3556dcf354e0b31984cd59ec7c039aa291a11a0613a3053a09282a12e9402711');"
                                data-original-title="View/Update TxNote"
                              >
                                View
                              </a>
                              <a
                                data-toggle="modal"
                                data-target="#myModaldelete"
                                className="btn btn-outline-secondary btn-xss html-link-custom"
                                onclick="javascript:getaddress('0x3556dcf354e0b31984cd59ec7c039aa291a11a0613a3053a09282a12e9402711');"
                                rel="tooltip"
                                title=""
                                data-original-title="Remove TxNote"
                              >
                                Remove
                              </a>
                            </td>
                            <td className="align-top">
                              <span className="d-block">
                                <a href="/tx/0x3556dcf354e0b31984cd59ec7c039aa291a11a0613a3053a09282a12e9402711">
                                  <span className="text-primary">0x3556dcf354e0b31984cd59ec7c039aa291a11a0613a3053a09282a12e9402711</span>
                                </a>
                              </span>
                              <span className="d-block small text-break" id="0x3556dcf354e0b31984cd59ec7c039aa291a11a0613a3053a09282a12e9402711">
                                adfff
                              </span>
                            </td>
                            <td className="align-top">2021-05-28</td>
                          </tr>
                          <tr>
                            <td className="align-top">
                              <a
                                data-toggle="modal"
                                data-target="#responsive"
                                className="btn btn-outline-secondary btn-xss html-link-custom mr-1"
                                rel="tooltip"
                                title=""
                                onclick="javascript:getnote('0x0a5f08afdf24a2efef2e71e1e6b0b3a80c4d5072d6da0709be69dc6e059c6587');"
                                data-original-title="View/Update TxNote"
                              >
                                View
                              </a>
                              <a
                                data-toggle="modal"
                                data-target="#myModaldelete"
                                className="btn btn-outline-secondary btn-xss html-link-custom"
                                onclick="javascript:getaddress('0x0a5f08afdf24a2efef2e71e1e6b0b3a80c4d5072d6da0709be69dc6e059c6587');"
                                rel="tooltip"
                                title=""
                                data-original-title="Remove TxNote"
                              >
                                Remove
                              </a>
                            </td>
                            <td className="align-top">
                              <span className="d-block">
                                <a href="/tx/0x0a5f08afdf24a2efef2e71e1e6b0b3a80c4d5072d6da0709be69dc6e059c6587">
                                  <span className="text-primary">0x0a5f08afdf24a2efef2e71e1e6b0b3a80c4d5072d6da0709be69dc6e059c6587</span>
                                </a>
                              </span>
                              <span className="d-block small text-break" id="0x0a5f08afdf24a2efef2e71e1e6b0b3a80c4d5072d6da0709be69dc6e059c6587">
                                adfff
                              </span>
                            </td>
                            <td className="align-top">2021-05-16</td>
                          </tr>
                        </tbody>
                      </table>
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

import {useState, useContext, useEffect} from 'react';
import {useRequest} from 'ahooks';
import UserContext from '../../UserContext';
import {useLocation} from 'react-router';
import qs from 'qs';

export default function Login() {
  const location = useLocation();
  const userContext = useContext(UserContext);
  const loginRequest = useRequest(
    body => ({
      url: '/blockBrowser/user/login/signIn',
      method: 'post',
      body: JSON.stringify(body),
    }),
    {manual: true, formatResult: r => r},
  );
  const [form, setForm] = useState({
    user_name: '',
    password: '',

    autologin: false,
  });

  useEffect(() => {
    const query = qs.parse(location.search, {ignoreQueryPrefix: true});

    if (query.cmd === 'logout') {
      window.localStorage.setItem('token', undefined);
      window.location.href = '/login';
    }
  }, []);

  const [errors, setErrors] = useState({});

  const handleForm = field => e => {
    setForm({...form, [field]: e.target.value});

    if (e.target.value) {
      setErrors({...errors, [field]: undefined});
    }
  };

  const handleSubmit = () => {
    const _errors = {};

    Object.keys(form)
      .filter(f => f !== 'autologin')
      .forEach(field => {
        _errors[field] = !form[field];
      });

    if (Object.keys(_errors).some(e => !!_errors[e])) {
      setErrors(_errors);
    } else {
      loginRequest.run({...form, autologin: undefined}).then(res => {
        console.log(res);
        if (res.data) {
          window.localStorage.setItem('token', res.data.token);

          window.location.href = '/';
        }
      });
    }
  };

  const handleBlur = key => () => {
    const _errors = {};

    Object.keys(form)
      .filter(f => f !== 'autologin')
      .forEach(field => {
        _errors[field] = !form[field];
      });

    if (Object.keys(_errors).some(e => !!_errors[e])) {
      if (_errors[key]) {
        setErrors(_errors);
      }
    }
  };

  const showError = typeof loginRequest.data?.code !== 'undefined' && loginRequest.data?.code !== 0;

  return (
    <main id="content" role="main">
      <div className="container space-2">
        <form method="post" className="js-validate w-md-75 w-lg-50 mx-md-auto" noValidate="novalidate">
          <div className="mb-4">
            <h2 className="h3 text-primary font-weight-normal mb-0">
              Welcome <span className="font-weight-semi-bold">back</span>
            </h2>
            <p>Login to your account</p>
          </div>
          <div className="alert alert-danger" style={{display: showError ? 'block' : 'none'}}>
            {loginRequest.data?.enMsg}
          </div>
          <div className={`js-form-message form-group ${errors.user_name ? 'u-has-error' : ''}`}>
            <label className="d-block" htmlFor="txtUserName">
              Username
            </label>
            <input
              type="text"
              maxLength="50"
              tabIndex="1"
              className="form-control form-control-sm"
              required=""
              data-error-class="u-has-error"
              data-success-class="u-has-success"
              placeholder="Username"
              data-msg="Username is required."
              value={form.user_name}
              onChange={handleForm('user_name')}
              onBlur={handleBlur('user_name')}
            />
            <div className="invalid-feedback" style={{display: errors.user_name ? 'block' : 'none'}}>
              Username is required.
            </div>
          </div>
          <div className={`js-form-message form-group ${errors.password ? 'u-has-error' : ''}`}>
            <label className="d-block" htmlFor="txtPassword">
              <span className="d-flex justify-content-between align-items-center">
                Password
                <a className="link-muted font-size-1" href="/lostpassword" tabIndex="0">
                  Forgot your password?
                </a>
              </span>
            </label>
            <input
              type="password"
              maxLength="75"
              tabIndex="2"
              className="form-control form-control-sm"
              ria-label="********"
              required=""
              data-error-class="u-has-error"
              data-success-class="u-has-success"
              placeholder="Password"
              data-msg="Your password is invalid. Please try again."
              value={form.password}
              onChange={handleForm('password')}
              onBlur={handleBlur('password')}
            />
            <div className="invalid-feedback" style={{display: errors.password ? 'block' : 'none'}}>
              Your password is invalid. Please try again.
            </div>
          </div>
          <div className="js-form-message">
            <div className="custom-control custom-checkbox d-flex align-items-center text-muted" onClick={() => handleForm('autologin')({target: {value: !form.autologin}})}>
              <input
                type="checkbox"
                className="custom-control-input"
                checked={form.autologin ? 'checked' : ''}
                onChange={e => {
                  console.log('e', e);
                }}
              />
              <label
                className="custom-control-label"
                htmlFor="ContentPlaceHolder1_chkRemember"
                data-toggle="tooltip"
                data-placement="bottom"
                data-title="Please do not check this box if you are using a public or shared PC"
                data-original-title=""
                title=""
              >
                <span>Remember &amp; Auto Login </span>
              </label>
            </div>
          </div>
          <div className="d-flex justify-content-center my-4">
            <input name="g-recaptcha-response" style={{visibility: 'hidden'}} />
          </div>
          <div className="row align-items-center">
            <div className="col-5 col-sm-6">
              <span className="text-muted text-nowrap">Don't have an account?</span>
              <a href="/register" className="text-nowrap">
                Click to sign up
              </a>
            </div>
            <div className="col-7 col-sm-6 text-right">
              <input type="button" onClick={handleSubmit} disabled={loginRequest.loading} value="LOGIN" className="btn btn-sm btn-primary" />
            </div>
          </div>
        </form>
      </div>
    </main>
  );
}

import {useEffect, useState} from 'react';
import {useRequest} from 'ahooks';
import {useLocation} from 'react-router-dom';
import qs from 'qs';
import Loading from '../../components/Loading';
import Pager from '../../components/Pager';

export default function Inventory({a, token, overview}) {
  const location = useLocation();
  const query = qs.parse(location.search, {ignoreQueryPrefix: true});
  const [state] = useState({
    body: {
      start: query.p || '1',
      length: '50',
      field: a ? 'address' : undefined,
      value: a,
      token_address: token,
    },
  });

  const tokenInventoryRequest = useRequest(
    body => ({
      url: '/blockBrowser/tokens/address/tokenInventory',
      method: 'post',
      body: JSON.stringify(body),
    }),
    {manual: true},
  );

  useEffect(() => {
    tokenInventoryRequest.run(state.body);
  }, []);

  if (tokenInventoryRequest.loading) {
    return <Loading />;
  }

  const data = tokenInventoryRequest.data?.token_inventory_list || [];
  const counts = tokenInventoryRequest.data?.counts || 0;
  const token_inventory_counts = tokenInventoryRequest.data?.token_inventory_counts || 0;
  const totalPage = Math.ceil(Number(counts) / state.body.length);
  const path=window.location.href
  const path1=path.substring(0,path.indexOf("?"))

  return (
    <div className="tab-pane fade show active">
      <div className="d-md-flex justify-content-between mb-4">
        <p className="mb-2 mb-md-0">
          <i id="spinwheel" className="fa fa-spin fa-spinner fa-1x fa-pulse mr-1" style={{display: 'none'}}></i>
          &nbsp;Latest {token_inventory_counts} active tokens (From a total of {counts} tokens)
        </p>
        <Pager path={`${path1}`} hash={'#inventory'} current={state.body.start} total={totalPage} />
      </div>
      <div id="resulttable">
        <table className="table table-hover">
          <thead className="thead-light">
            <tr>
              <th scope="col" width="1">
                Token_id
              </th>
              <th scope="col">Owner</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, i) => {
              return (
                <tr key={i}>
                  <td>
                    <a href={`/token/${token}?a=${parseInt(item.token_id,16)}`} target="_parent">
                      {parseInt(item.token_id,16)}
                    </a>
                  </td>
                  <td>
                    <a href={`/token/${token}?a=${item.holders_address}`} target="_parent">
                      {item.holders_address}
                    </a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="d-flex justify-content-md-end align-items-center text-secondary mb-2 mb-md-0 my-3">
        <div className="d-inline-block">
          <Pager path={`${path1}`} hash={'#inventory'} current={state.body.start} total={totalPage} />
        </div>
      </div>
    </div>
  );
}

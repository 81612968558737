import {useEffect} from 'react';
import {useRequest} from 'ahooks';

export default function VerifyCode({value, onChange}) {
  const verfiyCodeRequest = useRequest(
    body => ({
      url: '/blockBrowser/common/verifyCode',
      method: 'post',
      body: JSON.stringify(body),
    }),
    {manual: true},
  );

  useEffect(() => {
    verfiyCodeRequest.run();
  }, []);

  const handleRefresh = () => {
    verfiyCodeRequest.run();
  };

  return (
    <div style={{position: 'relative'}}>
      <input
        maxLength="100"
        className="form-control form-control-sm"
        type="email"
        placeholder="captcha"
        aria-label="Email address"
        data-msg="Please enter a valid captcha."
        value={value}
        onChange={e => {
          onChange({
            captcha_id: verfiyCodeRequest.data?.captcha_id,
            verify_code: e.target.value,
          });
        }}
      />
      {verfiyCodeRequest.data?.image_data ? (
        <img src={verfiyCodeRequest.data?.image_data} onClick={handleRefresh} style={{position: 'absolute', top: '1px', right: '1px', height: '39px', borderRadius: '4px'}} />
      ) : undefined}
    </div>
  );
}

import {useState} from 'react';
import {useLocation} from 'react-router-dom';

export default function Apis() {
  const location = useLocation();
  const [hash, setHash] = useState(location.hash.slice(1));
  console.log(location);
  const updatehash = hash => e => {
    setHash(hash);
  };

  return (
    <main id="content" role="main">
      <div className="container py-5">
        <div className="w-md-75 w-lg-50 text-center mx-md-auto mb-4">
          <h1 className="h2">Caduceus Chain Developer APIs</h1>
        </div>

        <div className="w-lg-75 mx-md-auto mb-4">
          <div className="row">
            <div className="col-6 col-sm-3 mb-5 mb-sm-0">
              <div className="text-center">
                <figure className="u-avatar mx-auto mb-3">
                  <img src="/images/svg/icons/icon-1.svg" alt="SVG" />
                </figure>
                <h3 className="h6 font-weight-normal">Access Blockchain Data</h3>
              </div>
            </div>
            <div className="col-6 col-sm-3 mb-5 mb-sm-0">
              <div className="text-center">
                <figure className="u-avatar mx-auto mb-3">
                  <img src="/images/svg/icons/icon-21.svg" alt="SVG" />
                </figure>
                <h3 className="h6 font-weight-normal">Building DAPPs</h3>
              </div>
            </div>
            <div className="col-6 col-sm-3">
              <div className="text-center">
                <figure className="u-avatar mx-auto mb-3">
                  <img src="/images/svg/icons/icon-2.svg" alt="SVG" />
                </figure>
                <h3 className="h6 font-weight-normal">Verify Contracts</h3>
              </div>
            </div>
            <div className="col-6 col-sm-3">
              <div className="text-center">
                <figure className="u-avatar mx-auto mb-3">
                  <img src="/images/svg/icons/icon-25.svg" alt="SVG" />
                </figure>
                <h3 className="h6 font-weight-normal">Community Driven</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <div className="list-group list-group-lg mb-3 nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
              <a
                className={`list-group-item list-group-item-action ${hash === '' ? 'active' : ''}`}
                id="tab-intro"
                data-toggle="tab"
                href="#intro"
                role="tab"
                aria-controls="intro"
                aria-selected="false"
                onClick={updatehash('')}
              >
                Introduction
              </a>
              <a
                className={`list-group-item list-group-item-action ${hash === 'accounts' ? 'active' : ''}`}
                id="accounts-tab"
                data-toggle="tab"
                href="#accounts"
                role="tab"
                aria-controls="accounts"
                aria-selected="false"
                onClick={updatehash('accounts')}
              >
                Accounts
              </a>
              <a
                className={`list-group-item list-group-item-action ${hash === 'contracts' ? 'active' : ''}`}
                id="contracts-tab"
                data-toggle="tab"
                href="#contracts"
                role="tab"
                aria-controls="contracts"
                aria-selected="false"
                onClick={updatehash('contracts')}
              >
                Contracts
              </a>
              <a
                className={`list-group-item list-group-item-action ${hash === 'transaction' ? 'active' : ''}`}
                id="transaction-tab"
                data-toggle="tab"
                href="#transaction"
                role="tab"
                aria-controls="transaction"
                aria-selected="false"
                onClick={updatehash('transaction')}
              >
                Transaction
              </a>
              <a
                className={`list-group-item list-group-item-action ${hash === 'block' ? 'active' : ''}`}
                id="block-tab"
                data-toggle="tab"
                href="#block"
                role="tab"
                aria-controls="block"
                aria-selected="false"
                onClick={updatehash('block')}
              >
                Block
              </a>
              <a
                className={`list-group-item list-group-item-action ${hash === 'log' ? 'active' : ''}`}
                id="log-tab"
                data-toggle="tab"
                href="#log"
                role="tab"
                aria-controls="log"
                aria-selected="false"
                onClick={updatehash('log')}
              >
                Log
              </a>
              <a
                className={`list-group-item list-group-item-action ${hash === 'tokens' ? 'active' : ''}`}
                id="tokens-tab"
                data-toggle="tab"
                href="#tokens"
                role="tab"
                aria-controls="tokens"
                aria-selected="false"
                onClick={updatehash('tokens')}
              >
                Tokens
              </a>
              <a
                className={`list-group-item list-group-item-action ${hash === 'stats' ? 'active' : ''}`}
                id="stats-tab"
                data-toggle="tab"
                href="#stats"
                role="tab"
                aria-controls="stats"
                aria-selected="false"
                onClick={updatehash('stats')}
              >
                Stats
              </a>
              {/* <a
                className={`list-group-item list-group-item-action ${hash === 'rpc' ? 'active' : ''}`}
                id="rpc-tab"
                data-toggle="tab"
                href="#rpc"
                role="tab"
                aria-controls="rpc"
                aria-selected="true"
                onClick={updatehash('rpc')}
              >
                Public RPC Nodes
              </a> */}
              <a
                className={`list-group-item list-group-item-action ${hash === 'proxy' ? 'active' : ''}`}
                id="proxy-tab"
                data-toggle="tab"
                href="#proxy"
                role="tab"
                aria-controls="proxy"
                aria-selected="true"
                onClick={updatehash('proxy')}
              >
                Proxy
              </a>
            </div>
          </div>

          <div className="col-md-9">
            <div className="tab-content" id="v-pills-tabContent">
              <div className={`card tab-pane fade ${hash === '' ? 'active show' : ''}`} id="intro" role="tabpanel" aria-labelledby="tab-intro">
                <div className="card-header">
                  <h2 className="h5 mb-0">Introduction</h2>
                </div>
                <div className="card-body px-4">
                  <br />
                  <p>
                    The Caduceus Chain Developer APIs are provided as a community service and without warranty, so please use what you need and no more. We support both GET/POST requests and there is
                    a rate limit of 5 calls per sec/IP.
                  </p>
                  <p>Note: Source attribution via a link back or mention that your app is "Powered by caduceusinfo.com APIs" is required except for personal/private usage.</p>
                  <br />
                </div>
              </div>

              <div className={`card tab-pane fade ${hash === 'accounts' ? 'active show' : ''}`} id="accounts" role="tabpanel" aria-labelledby="accounts-tab">
                <div className="card-header">
                  <h2 className="h5 mb-0">Account APIs</h2>
                </div>
                <div className="card-body px-4">
                  <div className="mb-4">
                    <br />
                    <h3 className="h6 font-weight-bold">Get CMP Balance for a single Address</h3>
                    <pre className="text-break-all">
                      {' '}
                      <a href="//mainnet.scan.caduceus.foundation/api?module=account&action=balance&address=0x8b62802d9f908a2ea380dbfc02a83fa74adf68f7&tag=latest">
                        https://mainnet.scan.caduceus.foundation/api?module=account&action=balance&address=0x8b62802d9f908a2ea380dbfc02a83fa74adf68f7&tag=latest
                      </a>
                    </pre>
                  </div>
                  <div className="mb-4">
                    <br />
                    <h3 className="h6 font-weight-bold">Get CMP Balance for multiple Addresses in a single call</h3>
                    <pre className="text-break-all">
                      {' '}
                      <a href="//mainnet.scan.caduceus.foundation/api?module=account&action=balancemulti&address=0xc9121e476155ebf0b794b7b351808af3787e727d,0x63a9975ba31b0b9626b34300f7f627147df1f526,0x198ef1ec325a96cc354c7266a038be8b5c558f67&tag=latest">
                        https://mainnet.scan.caduceus.foundation/api?module=account&action=balancemulti&address=0xc9121e476155ebf0b794b7b351808af3787e727d,0x63a9975ba31b0b9626b34300f7f627147df1f526,0x198ef1ec325a96cc354c7266a038be8b5c558f67&tag=latest
                      </a>
                    </pre>
                    <p className="mb-0">Separate addresses by comma, up to a maxium of 20 accounts in a single batch</p>
                  </div>
                  <div className="mb-4">
                    <br />
                    <h3 className="h6 font-weight-bold">Get CRC20-Token Account Balance for TokenContractAddress</h3>
                    <pre className="text-break-all">
                      {' '}
                      <a href="//mainnet.scan.caduceus.foundation/api?module=account&action=tokenbalance&address=0x8b62802d9f908a2ea380dbfc02a83fa74adf68f7&tag=latest">
                        https://mainnet.scan.caduceus.foundation/api?module=account&action=tokenbalance&address=0x8b62802d9f908a2ea380dbfc02a83fa74adf68f7&tag=latest
                      </a>
                    </pre>
                  </div>
                  <div className="mb-4">
                    <br />
                    <h3 className="h6 font-weight-bold">Get a list of 'Normal' Transactions By Address</h3>
                    <p className="mb-0">[Optional Parameters] startblock: starting blockNo to retrieve results, endblock: ending blockNo to retrieve results</p>
                    <pre className="text-break-all">
                      {' '}
                      <a href="//mainnet.scan.caduceus.foundation/api?module=account&action=txlist&address=0xf8975d8e203d5494739446377df4a1dc6a5cc516&startblock=13649070&endblock=13649110&sort=asc">
                        https://mainnet.scan.caduceus.foundation/api?module=account&action=txlist&address=0xf8975d8e203d5494739446377df4a1dc6a5cc516&startblock=13649070&endblock=13649110&sort=asc
                      </a>
                    </pre>
                    <p className="mb-0">(Returned 'isError' values: 0=No Error, 1=Got Error)</p>
                    <p className="mb-0">(Returns up to a maximum of the last 10000 transactions only)</p>
                    <p className="mb-0">
                      <strong>or</strong>
                    </p>
                    <pre className="text-break-all">
                      {' '}
                      <a href="//mainnet.scan.caduceus.foundation/api?module=account&action=txlist&address=0xf8975d8e203d5494739446377df4a1dc6a5cc516&startblock=13649000&endblock=13649110&page=0&offset=100&sort=asc">
                      https://mainnet.scan.caduceus.foundation/api?module=account&action=txlist&address=0xf8975d8e203d5494739446377df4a1dc6a5cc516&startblock=13649000&endblock=13649110&page=0&offset=100&sort=asc
                      </a>
                    </pre>
                    <p className="mb-0">(To get paginated results use page=&lt;page number&gt; and offset=&lt;max records to return&gt;)</p>
                  </div>
                  <div className="mb-4">
                    <br />
                    <h3 className="h6 font-weight-bold">Get a list of 'Internal' Transactions by Address</h3>
                    <p className="mb-0">[Optional Parameters] startblock: starting blockNo to retrieve results, endblock: ending blockNo to retrieve results</p>
                    <pre className="text-break-all">
                      {' '}
                      <a href="//mainnet.scan.caduceus.foundation/api?module=account&action=txlistinternal&address=0x46755f3b26e86287ac564a1960acff317ba944af&startblock=13636400&endblock=13649110&sort=asc">
                      https://mainnet.scan.caduceus.foundation/api?module=account&action=txlistinternal&address=0x46755f3b26e86287ac564a1960acff317ba944af&startblock=13636400&endblock=13649110&sort=asc
                      </a>
                    </pre>
                    <p className="mb-0">(Returned 'isError' values: 0=No Error, 1=Got Error)</p>
                    <p className="mb-0">(Returns up to a maximum of the last 10000 transactions only)</p>
                    <p className="mb-0">
                      <strong>or</strong>
                    </p>
                    <pre className="text-break-all">
                      {' '}
                      <a href="//mainnet.scan.caduceus.foundation/api?module=account&action=txlistinternal&address=0x46755f3b26e86287ac564a1960acff317ba944af&startblock=13636400&endblock=13649110&sort=asc&page=0&offset=10">
                      https://mainnet.scan.caduceus.foundation/api?module=account&action=txlistinternal&address=0x46755f3b26e86287ac564a1960acff317ba944af&startblock=13636400&endblock=13649110&sort=asc&page=0&offset=10
                      </a>
                    </pre>
                    <p className="mb-0">(To get paginated results use page=&lt;page number&gt; and offset=&lt;max records to return&gt;)</p>
                  </div>
                  <div className="mb-4">
                    <br />
                    <h3 className="h6 font-weight-bold">Get "Internal Transactions" by Transaction Hash</h3>
                    <pre className="text-break-all">
                      {' '}
                      <a href="//mainnet.scan.caduceus.foundation/api?module=account&action=txlistinternal&txhash=0x9d3eb3db57dac4beca27e42139d39562cfed21c8ea943781b049875e27f57e50">
                      https://mainnet.scan.caduceus.foundation/api?module=account&action=txlistinternal&txhash=0x9d3eb3db57dac4beca27e42139d39562cfed21c8ea943781b049875e27f57e50
                      </a>
                    </pre>
                    <p className="mb-0">(Returned 'isError' values: 0=Ok, 1=Rejected/Cancelled)</p>
                    <p className="mb-0">(Returns up to a maximum of the last 10000 transactions only)</p>
                  </div>
                  <div className="mb-4">
                    <br />
                    <h3 className="h6 font-weight-bold">Get "Internal Transactions" by Block Range</h3>
                    <pre className="text-break-all">
                      {' '}
                      <a href="//mainnet.scan.caduceus.foundation/api?module=account&action=txlistinternal&startblock=13636400&endblock=13649110&sort=asc&page=0&offset=10">
                      https://mainnet.scan.caduceus.foundation/api?module=account&action=txlistinternal&startblock=13636400&endblock=13649110&sort=asc&page=0&offset=10
                      </a>
                    </pre>
                    <p className="mb-0">(Returns up to a maximum of the last 10000 transactions only)</p>
                  </div>
                  <div className="mb-4">
                    <br />
                    <h3 className="h6 font-weight-bold">Get a list of "CRC-20 - Token Transfer Events" by Address</h3>
                    <p className="mb-0">[Optional Parameters] startblock: starting blockNo to retrieve results, endblock: ending blockNo to retrieve results</p>
                    <pre className="text-break-all">
                      {' '}
                      <a href="//mainnet.scan.caduceus.foundation/api?module=account&action=tokentx&address=0x46755f3b26e86287ac564a1960acff317ba944af&startblock=13636400&endblock=13636500&sort=asc">
                      https://mainnet.scan.caduceus.foundation/api?module=account&action=tokentx&address=0x46755f3b26e86287ac564a1960acff317ba944af&startblock=13636400&endblock=13636500&sort=asc                      </a>
                    </pre>
                    <p className="mb-0">(Returns up to a maximum of the last 10000 transactions only)</p>
                    <p className="mb-0">
                      <strong>or</strong>
                    </p>
                    <pre className="text-break-all">
                      {' '}
                      <a href="//mainnet.scan.caduceus.foundation/api?module=account&action=tokentx&address=0xe21a65491d925f8f66c4b254664a6b0bf08bb5a6&startblock=13654103&endblock=13654200&sort=asc&page=0&offset=2">
                      https://mainnet.scan.caduceus.foundation/api?module=account&action=tokentx&address=0xe21a65491d925f8f66c4b254664a6b0bf08bb5a6&startblock=13654103&endblock=13654200&sort=asc&page=0&offset=2                      </a>
                    </pre>
                    <p className="mb-0">(To get paginated results use page=&lt;page number&gt; and offset=&lt;max records to return&gt;)</p>
                    <p className="mb-0">
                      <strong>or</strong>
                    </p>
                    <pre className="text-break-all">
                      {' '}
                      <a href="//mainnet.scan.caduceus.foundation/api?module=account&action=tokentx&address=0x46755f3b26e86287ac564a1960acff317ba944af&contractaddress=0xcabf7e4c9d28f4957ba6f5558f81ef833fc2914e&startblock=13636400&endblock=13636500&sort=asc">
                      https://mainnet.scan.caduceus.foundation/api?module=account&action=tokentx&address=0x46755f3b26e86287ac564a1960acff317ba944af&contractaddress=0xcabf7e4c9d28f4957ba6f5558f81ef833fc2914e&startblock=13636400&endblock=13636500&sort=asc
                      </a>
                    </pre>
                    <p className="mb-0">(To get transfer events for a specific token contract, include the contractaddress parameter)</p>
                  </div>
                  
                  <div className="mb-4">
                    <br />
                    <h3 className="h6 font-weight-bold">Get a list of "CRC-721 - Token Transfer Events" by Address</h3>
                    <p className="mb-0">[Optional Parameters] startblock: starting blockNo to retrieve results, endblock: ending blockNo to retrieve results</p>
                    <pre className="text-break-all">
                      {' '}
                      <a href="//mainnet.scan.caduceus.foundation/api?module=account&action=tokennfttx&contractaddress=0x054b4afc2d71ca2042a80632cac472c06c14962d&startblock=13008800&endblock=13009000&sort=asc">
                      https://mainnet.scan.caduceus.foundation/api?module=account&action=tokennfttx&contractaddress=0x054b4afc2d71ca2042a80632cac472c06c14962d&startblock=13008800&endblock=13009000&sort=asc</a>
                    </pre>
                    <p className="mb-0">(Returns up to a maximum of the last 10000 transactions only)</p>
                    <p className="mb-0">
                      <strong>or</strong>
                    </p>
                    <pre className="text-break-all">
                      {' '}
                      <a href="//mainnet.scan.caduceus.foundation/api?module=account&action=tokennfttx&contractaddress=0x054b4afc2d71ca2042a80632cac472c06c14962d&startblock=13008800&endblock=13109000&page=0&offset=10&sort=asc">
                      https://mainnet.scan.caduceus.foundation/api?module=account&action=tokennfttx&contractaddress=0x054b4afc2d71ca2042a80632cac472c06c14962d&startblock=13008800&endblock=13109000&page=0&offset=10&sort=asc</a>
                    </pre>
                    <p className="mb-0">(To get paginated results use page=&lt;page number&gt; and offset=&lt;max records to return&gt;)</p>
                  </div>

                  <div className="mb-4">
                    <br />
                    <h3 className="h6 font-weight-bold">Get list of Blocks Validated by Address</h3>
                    <pre className="text-break-all">
                      {' '}
                      <a href="//mainnet.scan.caduceus.foundation/api?module=account&action=getminedblocks&address=0xb4270c32b5d4a6b16f31883ddde380d1f5d83af3&blocktype=blocks">
                      https://mainnet.scan.caduceus.foundation/api?module=account&action=getminedblocks&address=0xb4270c32b5d4a6b16f31883ddde380d1f5d83af3&blocktype=blocks
                      </a>
                    </pre>
                    <p className="mb-0">
                      <strong>or</strong>
                    </p>
                    <pre className="text-break-all">
                      {' '}
                      <a href="//mainnet.scan.caduceus.foundation/api?module=account&action=getminedblocks&address=0xb4270c32b5d4a6b16f31883ddde380d1f5d83af3&blocktype=blocks&page=1&offset=10">
                      https://mainnet.scan.caduceus.foundation/api?module=account&action=getminedblocks&address=0xb4270c32b5d4a6b16f31883ddde380d1f5d83af3&blocktype=blocks&page=1&offset=10
                      </a>
                    </pre>
                    <p className="mb-0">(To get paginated results use page=&lt;page number&gt; and offset=&lt;max records to return&gt;)</p>
                    <p className="mb-0">
                      ** type = <strong>blocks</strong> (full blocks only)
                    </p>
                  </div>
                </div>
              </div>

              <div className={`card tab-pane fade ${hash === 'contracts' ? 'active show' : ''}`} id="contracts" role="tabpanel" aria-labelledby="contracts-tab">
                <div className="card-header">
                  <h2 className="h5 mb-0">Contract APIs</h2>
                </div>
                <div className="card-body px-4">
                  <div className="mb-4">
                    <br />
                    <h3 className="h6 font-weight-bold">Get Contract ABI for Verified Contract Source Codes</h3>
                    <pre className="text-break-all">
                      {' '}
                      <a href="//mainnet.scan.caduceus.foundation/api?module=contract&action=getabi&address=0xefc5b320d4461f302190d458fd7e74cb8899cd1e">
                      https://mainnet.scan.caduceus.foundation/api?module=contract&action=getabi&address=0xefc5b320d4461f302190d458fd7e74cb8899cd1e
                      </a>
                    </pre>
                  </div>
                  <div className="mb-4">
                    <br />
                    <h3 className="h6 font-weight-bold">Get Contract Source Code for Verified Contract Source Codes</h3>
                    <pre className="text-break-all">
                      {' '}
                      <a href="//mainnet.scan.caduceus.foundation/api?module=contract&action=getsourcecode&address=0xefc5b320d4461f302190d458fd7e74cb8899cd1e">
                      https://mainnet.scan.caduceus.foundation/api?module=contract&action=getsourcecode&address=0xefc5b320d4461f302190d458fd7e74cb8899cd1e
                      </a>
                    </pre>
                  </div>
                  {/* <div className="mb-4">
                    <br />
                    <h3 className="h6 font-weight-bold">
                      Get Contract ABI for <a href="/contractsVerified">Verified Contract Source Codes</a>
                    </h3>
                    <pre className="text-break-all">
                      {' '}
                      <a href="//api.caduceusinfo.com/api?module=contract&action=getabi&address=0xc9121e476155ebf0b794b7b351808af3787e727d&apikey=YourApiKeyToken">
                        https://api.caduceusinfo.com/api?module=contract&action=getabi&address=0xc9121e476155ebf0b794b7b351808af3787e727d&apikey=YourApiKeyToken
                      </a>
                    </pre>
                  </div>
                  <div className="mb-4">
                    <p className="mb-0">A simple sample for retrieving the contractABI using Web3.js and Jquery to interact with a contract</p>
                    <pre className="bg-light border rounded p-3 mb-2">
                      {`    var Web3 = require('web3');
    var web3 = new Web3(new Web3.providers.HttpProvider());
    var version = web3.version.api;
            
    $.getJSON('https://api.caduceusinfo.com/api?module=contract&action=getabi&address=0xc9121e476155ebf0b794b7b351808af3787e727d&apikey=YourApiKeyToken', function (data) {
    var contractABI = "";
        contractABI = JSON.parse(data.result);
        if (contractABI != ''){
            var MyContract = web3.eth.contract(contractABI);
            var myContractInstance = MyContract.at("0xc9121e476155ebf0b794b7b351808af3787e727d");
            var result = myContractInstance.memberId("0xfe8ad7dd2f564a877cc23feea6c0a9cc2e783715");
            console.log("result1 : " + result);
            var result = myContractInstance.members(1);
            console.log("result2 : " + result);
        } else {
            console.log("Error" );
        }
    });`}
                    </pre>
                  </div>
                  <div className="mb-4">
                    <br />
                    <h3 className="h6 font-weight-bold">
                      Get Contract Source Code for <a href="/contractsVerified">Verified Contract Source Codes</a>
                    </h3>
                    <pre className="text-break-all">
                      {' '}
                      1.{' '}
                      <a href="//api.caduceusinfo.com/api?module=contract&action=getsourcecode&address=0xc9121e476155ebf0b794b7b351808af3787e727d&apikey=YourApiKeyToken">
                        https://api.caduceusinfo.com/api?module=contract&action=getsourcecode&address=0xc9121e476155ebf0b794b7b351808af3787e727d&apikey=YourApiKeyToken
                      </a>{' '}
                      (replace the address parameter with the actual contract address)
                      <br />
                      <br />
                      2. Terms of usage: Please see the <a href="source-code-usage-terms">usage terms policy</a>
                      <br />
                    </pre>
                  </div>
                  <div className="mb-4">
                    <br />
                    <h3 className="h6 font-weight-bold">Verify Source Code (beta)</h3>
                    <pre className="text-break p-3 mb-2">
                      {' '}
                      1. Requires a valid caduceusinfo APIkey, will reject if otherwise
                      <br />
                      2. Current daily limit of 100 submissions per day per user (subject to change)
                      <br />
                      3. Only supports HTTP post due to max transfer size limitations for http get
                      <br />
                      4. Supports up to 10 different library pairs
                      <br />
                      5. Contracts that use "imports" will need to have the code concatenated into one file as we do not support "imports" in separate files. You can try using the{' '}
                      <a rel="nofollow noopener" href="https://github.com/BlockCatIO/solidity-flattener" target="_blank">
                        <span className="text-primary">Blockcat solidity-flattener</span>
                      </a>{' '}
                      or{' '}
                      <a href="https://github.com/DaveAppleton/SolidityFlattery" target="_blank" rel="nofollow">
                        <span className="text-primary">SolidityFlattery</span>
                      </a>
                      <br />
                      6. List of <a href="//caduceusinfo.com/solcversions">supported solc versions</a>, only solc version v0.4.11 and above is supported. Ex. v0.4.25+commit.59dbf8f1
                      <br />
                      7. Upon successful submission you will receive a GUID (50 characters) as a receipt.
                      <br />
                      8. You may use this GUID to track the status of your submission
                      <br />
                      9. Verified Source Codes will be displayed at <a href="/contractsVerified">contractsVerified</a>
                    </pre>
                  </div>
                  <div className="mb-4">
                    <p className="mb-0">
                      See Demo Source Verification Submission Code at{' '}
                      <a href="/sourcecode-demo.html" target="_blank">
                        Source Code Verification Sample <i className="fa fa-external-link"></i>
                      </a>
                    </p>
                  </div>
                  <div className="mb-4">
                    <p className="mb-0">Source Code Submission Gist (returns a guid as part of the result upon success):</p>
                    <pre className="bg-light border rounded p-3 mb-2">
                      {`    //Submit Source Code for Verification
    $.ajax({
        type: "POST",                       //Only POST supported  
        url: "//api.caduceusinfo.com/api", //Set to the  correct API url for Other Networks
        data: {
            apikey: $('#apikey').val(),                     //A valid API-Key is required        
            module: 'contract',                             //Do not change
            action: 'verifysourcecode',                     //Do not change
            contractaddress: $('#contractaddress').val(),   //Contract Address starts with 0x...     
            sourceCode: $('#sourceCode').val(),             //Contract Source Code (Flattened if necessary)
            codeformat: $('#codeformat').val(),             //solidity-single-file (default) or solidity-standard-json-input (for std-input-json-format support
            contractname: $('#contractname').val(),         //ContractName (if codeformat=solidity-standard-json-input, then enter contractname as ex: erc20.sol`}
                      <b>:</b>
                      {`erc20)
            compilerversion: $('#compilerversion').val(),   // see https://caduceusinfo.com/solcversions for list of support versions
            optimizationUsed: $('#optimizationUsed').val(), //0 = No Optimization, 1 = Optimization used (applicable when codeformat=solidity-single-file)
            runs: 200,                                      //set to 200 as default unless otherwise  (applicable when codeformat=solidity-single-file)        
            constructorArguements: $('#constructorArguements').val(),   //if applicable
            evmversion: $('#evmVersion').val(),             //leave blank for compiler default, homestead, tangerineWhistle, spuriousDragon, byzantium, constantinople, petersburg, istanbul (applicable when codeformat=solidity-single-file)
            licenseType: $('#licenseType').val(),           //Valid codes 1-12 where 1=No License .. 12=Apache 2.0, see https://caduceusinfo.com/contract-license-types
            libraryname1: $('#libraryname1').val(),         //if applicable, a matching pair with libraryaddress1 required
            libraryaddress1: $('#libraryaddress1').val(),   //if applicable, a matching pair with libraryname1 required
            libraryname2: $('#libraryname2').val(),         //if applicable, matching pair required
            libraryaddress2: $('#libraryaddress2').val(),   //if applicable, matching pair required
            libraryname3: $('#libraryname3').val(),         //if applicable, matching pair required
            libraryaddress3: $('#libraryaddress3').val(),   //if applicable, matching pair required
            libraryname4: $('#libraryname4').val(),         //if applicable, matching pair required
            libraryaddress4: $('#libraryaddress4').val(),   //if applicable, matching pair required
            libraryname5: $('#libraryname5').val(),         //if applicable, matching pair required
            libraryaddress5: $('#libraryaddress5').val(),   //if applicable, matching pair required
            libraryname6: $('#libraryname6').val(),         //if applicable, matching pair required
            libraryaddress6: $('#libraryaddress6').val(),   //if applicable, matching pair required
            libraryname7: $('#libraryname7').val(),         //if applicable, matching pair required
            libraryaddress7: $('#libraryaddress7').val(),   //if applicable, matching pair required
            libraryname8: $('#libraryname8').val(),         //if applicable, matching pair required
            libraryaddress8: $('#libraryaddress8').val(),   //if applicable, matching pair required
            libraryname9: $('#libraryname9').val(),         //if applicable, matching pair required
            libraryaddress9: $('#libraryaddress9').val(),   //if applicable, matching pair required
            libraryname10: $('#libraryname10').val(),       //if applicable, matching pair required
            libraryaddress10: $('#libraryaddress10').val()  //if applicable, matching pair required
        },
        success: function (result) {
            console.log(result);
            if (result.status == "1") {
                //1 = submission success, use the guid returned (result.result) to check the status of your submission.
                // Average time of processing is 30-60 seconds
                document.getElementById("postresult").innerHTML = result.status + ";" + result.message + ";" + result.result;
                // result.result is the GUID receipt for the submission, you can use this guid for checking the verification status
            } else {
                //0 = error
                document.getElementById("postresult").innerHTML = result.status + ";" + result.message + ";" + result.result;
            }
            console.log("status : " + result.status);
            console.log("result : " + result.result);
        },
        error: function (result) {
            console.log("error!");
            document.getElementById("postresult").innerHTML = "Unexpected Error"
        }
    });`}
                    </pre>
                  </div>
                  <div className="mb-4">
                    <p className="mb-0">Check Source code verification submission status:</p>
                    <pre className="bg-light border rounded p-3 mb-2">
                      {`    //Check Source Code Verification Status
    $.ajax({
        type: "GET",
        url: "//api.caduceusinfo.com/api",
        data: {
            apikey: $('#apikey').val(), 
            guid: 'ezq878u486pzijkvvmerl6a9mzwhv6sefgvqi5tkwceejc7tvn', //Replace with your Source Code GUID receipt above
            module: "contract",
            action: "checkverifystatus"
        },
        success: function (result) {
            console.log("status : " + result.status);   //0=Error, 1=Pass 
            console.log("message : " + result.message); //OK, NOTOK
            console.log("result : " + result.result);   //result explanation
            $('#guidstatus').html(">> " + result.result);
        },
        error: function (result) {
            alert('error');
        }
    });`}
                    </pre>
                  </div> */}
                </div>
              </div>

              <div className={`card tab-pane fade ${hash === 'transaction' ? 'active show' : ''}`} id="transaction" role="tabpanel" aria-labelledby="transaction-tab">
                <div className="card-header">
                  <h2 className="h5 mb-0">Transaction Info APIs</h2>
                </div>
                <div className="card-body px-4">
                  <div className="mb-4">
                    <br />
                    <h3 className="h6 font-weight-bold">Check Transaction Receipt Status</h3>
                    <p className="mb-0">Note: status: 0 = Fail, 1 = Pass.</p>
                    <pre className="text-break-all">
                      {' '}
                      <a href="//mainnet.scan.caduceus.foundation/api?module=transaction&action=gettxreceiptstatus&txhash=0x7fe7b67d1ebce8291496b82fa82d761077c0492e4449b0e4bcda6408f2f4db2e">
                      https://mainnet.scan.caduceus.foundation/api?module=transaction&action=gettxreceiptstatus&txhash=0x7fe7b67d1ebce8291496b82fa82d761077c0492e4449b0e4bcda6408f2f4db2e
                      </a>
                    </pre>
                  </div>
                </div>
              </div>

              <div className={`card tab-pane fade ${hash === 'block' ? 'active show' : ''}`} id="block" role="tabpanel" aria-labelledby="block-tab">
                <div className="card-header">
                  <h2 className="h5 mb-0">Block APIs</h2>
                </div>
                <div className="card-body px-4">
                <div className="mb-4">
                    <br />
                    <h3 className="h6 font-weight-bold">Get Block Rewards by BlockNo</h3>
                    <pre className="text-break-all">
                      {' '}
                      <a href="//mainnet.scan.caduceus.foundation/api?module=block&action=getblockreward&blockno=1378">
                      https://mainnet.scan.caduceus.foundation/api?module=block&action=getblockreward&blockno=1378
                      </a>
                    </pre>
                  </div>
                  <div className="mb-4">
                    <br />
                    <h3 className="h6 font-weight-bold">Get Estimated Block Countdown Time by BlockNo</h3>
                    <pre className="text-break-all">
                      {' '}
                      <a href="//mainnet.scan.caduceus.foundation/api?module=block&action=getblockcountdown&blockno=1378">
                      https://mainnet.scan.caduceus.foundation/api?module=block&action=getblockcountdown&blockno=1378
                      </a>
                    </pre>
                  </div>
                  <div className="mb-4">
                    <br />
                    <h3 className="h6 font-weight-bold">Get Block Number by Timestamp</h3>
                    <p className="mb-0">[Parameters] timestamp format: Unix timestamp (supports Unix timestamps in seconds), closest value: 'before' or 'after'</p>
                    <pre className="text-break-all">
                      {' '}
                      <a href="//mainnet.scan.caduceus.foundation/api?module=block&action=getblocknobytime&timestamp=1644245532&closest=after">
                      https://mainnet.scan.caduceus.foundation/api?module=block&action=getblocknobytime&timestamp=1644245532&closest=after
                      </a>
                    </pre>
                  </div>
                </div>
              </div>

              <div className={`card tab-pane fade ${hash === 'log' ? 'active show' : ''}`} id="log" role="tabpanel" aria-labelledby="log-tab">
                <div className="card-header">
                  <h2 className="h5 mb-0">Log APIs</h2>
                </div>
                <div className="card-body px-4">
                <div className="mb-4">
                    <br />
                    <p className="mb-0">The Event Log API was designed to provide an alternative to the native eth_getLogs. </p>
                    <p className="mb-0">This is a POST request.</p>
                    <pre className="text-break-all">
                      {' '}
                      <a href="//mainnet.scan.caduceus.foundation/api?module=log&action=getLogs">
                      https://mainnet.scan.caduceus.foundation/api?module=log&action=getLogs
                      </a>
                    </pre>
                    <p className="mb-0">Here are some example of how this api may be used:</p>
                  </div>
                  <div className="mb-4">
                    <br />
                    <h3 className="h6 font-weight-bold">Get event logs from block number 13920 to block number 13930, where log address is 0x715aA09E6950ffDBda55Cea77f72dd7F52Ae1A62 and topic0 is "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef"</h3>
                    <pre className="bg-light border rounded p-3 mb-2">
                    {`
  "toBlock":13920,
  "fromBlock":13930,
  "address":"0x715aA09E6950ffDBda55Cea77f72dd7F52Ae1A62",
  "topics":[
      ["0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef"]
  ]
                    `}
                    </pre>
                  </div>
                </div>
                <div className="card-body px-4">
                  <div className="mb-4">
                    <br />
                    <h3 className="h6 font-weight-bold">Get event logs from block number 13920 to block number 13930, where log address is 0x715aA09E6950ffDBda55Cea77f72dd7F52Ae1A62 and topic0,topic1 is nay and topic3 is "0x00000000000000000000000075ecd1ab06c4e34763a47e1033e80de614d09fa4"</h3>
                    <pre className="bg-light border rounded p-3 mb-2">
                    {`
  "toBlock":13920,
  "fromBlock":13930,
  "address":"0x715aA09E6950ffDBda55Cea77f72dd7F52Ae1A62",
  "topics":[
      [],
      [],
      ["0x00000000000000000000000075ecd1ab06c4e34763a47e1033e80de614d09fa4"]
  ]
                    `}
                    </pre>
                    <p className="mb-0">Tips: max range between "fromBlock" and "toBlock" is 10000.</p>
                  </div>
                </div>
              </div>

              <div className={`card tab-pane fade ${hash === 'tokens' ? 'active show' : ''}`} id="tokens" role="tabpanel" aria-labelledby="tokens-tab">
                <div className="card-header">
                  <h2 className="h5 mb-0">Token Info APIs</h2>
                </div>
                <div className="card-body px-4">
                  <div className="mb-4">
                    <br />
                    <h3 className="h6 font-weight-bold">Get CRC20-Token TotalSupply by ContractAddress</h3>
                    <pre className="text-break-all">
                      {' '}
                      <a href="//mainnet.scan.caduceus.foundation/api?module=stats&action=tokensupply&contractaddress=0xd378634119d2f7b3cf3d60e0b0f5e048e74ce3cf">
                      https://mainnet.scan.caduceus.foundation/api?module=stats&action=tokensupply&contractaddress=0xd378634119d2f7b3cf3d60e0b0f5e048e74ce3cf
                      </a>
                    </pre>
                  </div>
                  <div className="mb-4">
                    <br />
                    <h3 className="h6 font-weight-bold">Get CRC20-Token Circulating Supply by ContractAddress</h3>
                    <pre className="text-break-all">
                      {' '}
                      <a href="//mainnet.scan.caduceus.foundation/api?module=stats&action=tokenCsupply&contractaddress=0xd378634119d2f7b3cf3d60e0b0f5e048e74ce3cf">
                      https://mainnet.scan.caduceus.foundation/api?module=stats&action=tokenCsupply&contractaddress=0xd378634119d2f7b3cf3d60e0b0f5e048e74ce3cf
                      </a>
                    </pre>
                  </div>
                  <div className="mb-4">
                    <h3 className="h6 font-weight-bold">Get CRC20-Token Account Balance for TokenContractAddress</h3>
                    <pre className="text-break-all">
                      {' '}
                      <a href="//mainnet.scan.caduceus.foundation/api?module=account&action=tokenbalance&contractaddress=0xd378634119d2f7b3cf3d60e0b0f5e048e74ce3cf&address=0x62f446c1bfe201b5ecf69c735c8bbf3fe90e0528&tag=latest">
                      https://mainnet.scan.caduceus.foundation/api?module=account&action=tokenbalance&contractaddress=0xd378634119d2f7b3cf3d60e0b0f5e048e74ce3cf&address=0x62f446c1bfe201b5ecf69c735c8bbf3fe90e0528&tag=latest
                      </a>
                    </pre>
                  </div>
                </div>
              </div>

              <div className={`card tab-pane fade ${hash === 'stats' ? 'active show' : ''}`} id="stats" role="tabpanel" aria-labelledby="stats-tab">
                <div className="card-header">
                  <h2 className="h5 mb-0">General Stats APIs</h2>
                </div>
                <div className="card-body px-4">
                  <div className="mb-4">
                    <br />
                    <h3 className="h6 font-weight-bold">Get Total Supply of CMP on the CMP Chain</h3>
                    <pre className="text-break-all">
                      {' '}
                      <a href="//mainnet.scan.caduceus.foundation/api?module=stats&action=cmpsupply">https://mainnet.scan.caduceus.foundation/api?module=stats&action=cmpsupply</a>
                    </pre>
                    <p className="mb-0">(Result returned in Wei, to get value in CMP divide the ResultAbove/1000000000000000000)</p>
                  </div>
                  <div className="mb-4">
                    <br />
                    <h3 className="h6 font-weight-bold">Get CMP Last Price</h3>
                    <pre className="text-break-all">
                      {' '}
                      <a href="//mainnet.scan.caduceus.foundation/api?module=stats&action=cmpprice">https://mainnet.scan.caduceus.foundation/api?module=stats&action=cmpprice</a>
                    </pre>
                  </div>
                </div>
              </div>

              <div className={`card tab-pane fade ${hash === 'proxy' ? 'active show' : ''}`} id="proxy" role="tabpanel" aria-labelledby="proxy-tab">
                <div className="card-header">
                  <h2 className="h5 mb-0">Proxy APIs</h2>
                </div>
                <div className="card-body px-4">
                  <div className="mb-4">
                    <br />
                    <h3 className="h6 font-weight-bold">
                    The following are the limited list of supported Proxied APIs for CMP available through HScan.
                    </h3>
                    <br />
                    <h3 className="h6 font-weight-bold">eth_blockNumber</h3>
                    <p className="mb-0">Returns the number of most recent block</p>
                    <pre className="text-break-all">
                      {' '}
                      <a href="//mainnet.scan.caduceus.foundation/api?module=proxy&action=blockNumber">
                      https://mainnet.scan.caduceus.foundation/api?module=proxy&action=blockNumber</a>
                    </pre>
                  </div>
                  <div className="mb-4">
                    <br />
                    <h3 className="h6 font-weight-bold">eth_getBlockByNumber</h3>
                    <p className="mb-0">Returns information about a block by block number</p>
                    <pre className="text-break-all">
                      {' '}
                      <a href="//mainnet.scan.caduceus.foundation/api?module=proxy&action=getBlockByNumber&tag=0xd3d8&boolean=true">
                      https://mainnet.scan.caduceus.foundation/api?module=proxy&action=getBlockByNumber&tag=0xd3d8&boolean=true</a>
                    </pre>
                  </div>
                  <div className="mb-4">
                    <br />
                    <h3 className="h6 font-weight-bold">eth_getBlockTransactionCountByNumber</h3>
                    <p className="mb-0">Returns the number of transactions in a block from a block matching the given block number</p>
                    <pre className="text-break-all">
                      {' '}
                      <a href="//mainnet.scan.caduceus.foundation/api?module=proxy&action=getBlockTransactionCountByNumber&tag=0xd3dde8">
                      https://mainnet.scan.caduceus.foundation/api?module=proxy&action=getBlockTransactionCountByNumber&tag=0xd3dde8</a>
                    </pre>
                  </div>
                  <div className="mb-4">
                    <br />
                    <h3 className="h6 font-weight-bold">eth_getTransactionByHash</h3>
                    <p className="mb-0">Returns the information about a transaction requested by transaction hash</p>
                    <pre className="text-break-all">
                      {' '}
                      <a href="//mainnet.scan.caduceus.foundation/api?module=proxy&action=getTransactionByHash&txhash=0xb40f0f6a83afba8a305938b776b3dfb11a63ac73f3e5eb53b8816d10db04cb84">
                      https://mainnet.scan.caduceus.foundation/api?module=proxy&action=getTransactionByHash&txhash=0xb40f0f6a83afba8a305938b776b3dfb11a63ac73f3e5eb53b8816d10db04cb84</a>
                    </pre>
                  </div>
                  <div className="mb-4">
                    <br />
                    <h3 className="h6 font-weight-bold">eth_getTransactionByBlockNumberAndIndex</h3>
                    <p className="mb-0">Returns information about a transaction by block number and transaction index position</p>
                    <pre className="text-break-all">
                      {' '}
                      <a href="//mainnet.scan.caduceus.foundation/api?module=proxy&action=getTransactionByBlockNumberAndIndex&tag=0x1DCBD9&index=0x0">
                      https://mainnet.scan.caduceus.foundation/api?module=proxy&action=getTransactionByBlockNumberAndIndex&tag=0x1DCBD9&index=0x0</a>
                    </pre>
                  </div>

                  <div className="mb-4">
                    <br />
                    <h3 className="h6 font-weight-bold">eth_getTransactionCount</h3>
                    <p className="mb-0">Returns the number of transactions sent from an address</p>
                    <pre className="text-break-all">
                      {' '}
                      <a href="//mainnet.scan.caduceus.foundation/api?module=proxy&action=getTransactionCount&address=0x2d104cc9db395d5bc1d0043f7be3f8f5c1cffc88&tag=latest">
                      https://mainnet.scan.caduceus.foundation/api?module=proxy&action=getTransactionCount&address=0x2d104cc9db395d5bc1d0043f7be3f8f5c1cffc88&tag=latest</a>
                    </pre>
                  </div>
                  <div className="mb-4">
                    <br />
                    <h3 className="h6 font-weight-bold">eth_sendRawTransaction</h3>
                    <p className="mb-0">Creates new message call transaction or a contract creation for signed transactions</p>
                    <pre className="text-break-all">
                      {' '}
                      <a href="//mainnet.scan.caduceus.foundation/api?module=proxy&action=sendRawTransaction&hex=0xf8ab1385ba43b7400082afc894715aa09e6950ffdbda55cea77f72dd7f52ae1a6280b84440c10f1900000000000000000000000075ecd1ab06c4e34763a47e1033e80de614d09fa4000000000000000000000000000000000000000000000000000000014b1a92c082023ea0fc44da2f85b9fc598124c81ed13a62b49e6edc6248d8c4940ed9a192f62717cea05832a187f1d413c0fa27669ad277f2bcaa390827c420e314af44d8ee194c0eb3">
                      https://mainnet.scan.caduceus.foundation/api?module=proxy&action=sendRawTransaction&hex=0xf8ab1385ba43b7400082afc894715aa09e6950ffdbda55cea77f72dd7f52ae1a6280b84440c10f1900000000000000000000000075ecd1ab06c4e34763a47e1033e80de614d09fa4000000000000000000000000000000000000000000000000000000014b1a92c082023ea0fc44da2f85b9fc598124c81ed13a62b49e6edc6248d8c4940ed9a192f62717cea05832a187f1d413c0fa27669ad277f2bcaa390827c420e314af44d8ee194c0eb3</a>
                    </pre>
                    <p className="mb-0">(Replace the hex value with your raw hex encoded transaction that you want to send.)</p>
                  </div>
                  <div className="mb-4">
                    <br />
                    <h3 className="h6 font-weight-bold">eth_getTransactionReceipt</h3>
                    <p className="mb-0">Returns the receipt of a transaction by transaction hash</p>
                    <pre className="text-break-all">
                      {' '}
                      <a href="//mainnet.scan.caduceus.foundation/api?module=proxy&action=getTransactionReceipt&txhash=0x4d4c7996d8c52ea12716408cc8a2c0d3fcd7eafc1f7fbb59485bd12b685204e0">
                      https://mainnet.scan.caduceus.foundation/api?module=proxy&action=getTransactionReceipt&txhash=0x4d4c7996d8c52ea12716408cc8a2c0d3fcd7eafc1f7fbb59485bd12b685204e0</a>
                    </pre>
                  </div>
                  <div className="mb-4">
                    <br />
                    <h3 className="h6 font-weight-bold">eth_call</h3>
                    <p className="mb-0">Executes a new message call immediately without creating a transaction on the block chain</p>
                    <pre className="text-break-all">
                      {' '}
                      <a href="//mainnet.scan.caduceus.foundation/api?module=proxy&action=call&to=0xbe05ac1fb417c9ea435b37a9cecd39bc70359d31&data=0x18160ddd&tag=latest">
                      https://mainnet.scan.caduceus.foundation/api?module=proxy&action=call&to=0xbe05ac1fb417c9ea435b37a9cecd39bc70359d31&data=0x18160ddd&tag=latest</a>
                    </pre>
                    <p className="mb-0">(The gas parameter to eth_call is 10000000)</p>
                  </div>
                  <div className="mb-4">
                    <br />
                    <h3 className="h6 font-weight-bold">eth_getCode</h3>
                    <p className="mb-0">Returns code at a given address</p>
                    <pre className="text-break-all">
                      {' '}
                      <a href="//mainnet.scan.caduceus.foundation/api?module=proxy&action=getCode&address=0xbe05ac1fb417c9ea435b37a9cecd39bc70359d31&tag=latest">
                      https://mainnet.scan.caduceus.foundation/api?module=proxy&action=getCode&address=0xbe05ac1fb417c9ea435b37a9cecd39bc70359d31&tag=latest</a>
                    </pre>
                  </div>
                  <div className="mb-4">
                    <br />
                    <h3 className="h6 font-weight-bold">eth_getStorageAt</h3>
                    <p className="mb-0">Returns the value from a storage position at a given address</p>
                    <pre className="text-break-all">
                      {' '}
                      <a href="//mainnet.scan.caduceus.foundation/api?module=proxy&action=getStorageAt&address=0xbe05ac1fb417c9ea435b37a9cecd39bc70359d31&position=0x0&tag=latest">
                      https://mainnet.scan.caduceus.foundation/api?module=proxy&action=getStorageAt&address=0xbe05ac1fb417c9ea435b37a9cecd39bc70359d31&position=0x0&tag=latest</a>
                    </pre>
                  </div>
                  <div className="mb-4">
                    <br />
                    <h3 className="h6 font-weight-bold">eth_gasPrice</h3>
                    <p className="mb-0">Returns the current price per gas in wei</p>
                    <pre className="text-break-all">
                      {' '}
                      <a href="//mainnet.scan.caduceus.foundation/api?module=proxy&action=gasPrice">
                      https://mainnet.scan.caduceus.foundation/api?module=proxy&action=gasPrice</a>
                    </pre>
                  </div>
                  <div className="mb-4">
                    <br />
                    <h3 className="h6 font-weight-bold">eth_estimateGas</h3>
                    <p className="mb-0">Makes a call or transaction, which won't be added to the blockchain and returns the used gas, which can be used for estimating the used gas</p>
                    <pre className="text-break-all">
                      {' '}
                      <a href="//mainnet.scan.caduceus.foundation/api?module=proxy&action=estimateGas&data=0x40c10f1900000000000000000000000075ecd1ab06c4e34763a47e1033e80de614d09fa4000000000000000000000000000000000000000000000000000000014b1a92c0&to=0x715aa09e6950ffdbda55cea77f72dd7f52ae1a62&value=0x0&gasPrice=0x430E23400&gas=0xF4240">
                      https://mainnet.scan.caduceus.foundation/api?module=proxy&action=estimateGas&data=0x40c10f1900000000000000000000000075ecd1ab06c4e34763a47e1033e80de614d09fa4000000000000000000000000000000000000000000000000000000014b1a92c0&to=0x715aa09e6950ffdbda55cea77f72dd7f52ae1a62&value=0x0&gasPrice=0x430E23400&gas=0xF4240</a>
                    </pre>
                    <p className="mb-0">(The gas parameter to eth_estimateGas is 10000000)</p>
                  </div>
                </div>
              </div>

              <div className={`card tab-pane fade ${hash === 'rpc' ? 'active show' : ''}`} id="rpc" role="tabpanel" aria-labelledby="rpc-tab">
                <div className="card-header">
                  <h2 className="h5 mb-0">Public Caduceus RPC Nodes</h2>
                </div>
                <div className="card-body px-4">
                  <div className="mb-4">
                    <br />
                    <h3 className="h6 font-weight-bold">Mainnet Caduceus RPC Nodes</h3>
                    <pre className="text-break-all">
                      {' '}
                      Mainnet Caduceus RPC Endpoints (ChainID 128):
                      <br />
                      <br />
                      https://http-mainnet.caduceuschain.com <br />
                      https://http-mainnet-node.caduceuschain.com (China Mainland)
                    </pre>
                  </div>
                  <div className="mb-4">
                    <br />
                    <h3 className="h6 font-weight-bold">Testnet Caduceus RPC Nodes</h3>
                    <pre className="text-break-all">
                      {' '}
                      Testnet Caduceus RPC Endpoints (ChainID 512512):
                      <br />
                      <br />
                      https://http-testnet.caduceuschain.com
                    </pre>
                  </div>
                  <div className="mb-4">
                    <br />
                    <h3 className="h6 font-weight-bold">Usage Notes:</h3>
                    <pre className="text-break-all">
                      {' '}
                      Start
                      <br />
                      You can start the HTTP JSON-RPC with the --rpc flag
                      <br />
                      <br />
                      ## mainnet
                      <br />
                      geth attach https://http-mainnet.caduceuschain.com
                      <br />
                      <br />
                      ## testnet
                      <br />
                      geth attach https://http-testnet.caduceuschain.com
                      <br />
                      <br />
                      JSON-RPC methods
                      <br />
                      Please refer to this wiki page or use Postman:{' '}
                      <a href="https://documenter.getpostman.com/view/4117254/ethereum-json-rpc/RVu7CT5J?version=latest" target="_blank">
                        https://documenter.getpostman.com/view/4117254/ethereum-json-rpc/RVu7CT5J?version=latest
                      </a>{' '}
                      <br />
                    </pre>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

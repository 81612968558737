import {useState, useContext} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import {useRequest} from 'ahooks';
import Modal from 'react-bootstrap/Modal';
import MySideBar from '../../components/MySideBar';
import UserContext from '../../UserContext';

function OverviewTab({onChange}) {
  const userContext = useContext(UserContext);
  const user = userContext.user || {};

  return (
    <div className="tab-pane fade active show" role="tabpanel" aria-labelledby="overview-tab">
      <p className="ml-2">Below are the username, email and overview information for your account.</p>
      <div className="table-responsive py-2">
        <div className="mx-md-auto">
          <figure className="mb-7 ie-data-report">
            <table className="table">
              <tbody>
                <tr>
                  <td>
                    <div className="py-2">Your Username :</div>
                  </td>
                  <td>
                    <div className="d-flex justify-content-between">
                      <div className="p-2">{user.user_name}</div>
                      <div className="p-2"></div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="py-2">Your Email Address :</div>
                  </td>
                  <td>
                    <div className="d-flex justify-content-between">
                      <div className="p-2">{user.email}</div>
                      <div className="p-2">
                        <a
                          href="#profile"
                          className="js-tab-external-link"
                          data-type="static"
                          onClick={e => {
                            onChange({action: 'switchTab'});
                          }}
                        >
                          <i className="fas fa-pencil-alt"></i>
                        </a>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="py-2">Address Watch List :</div>
                  </td>
                  <td>
                    <div className="d-flex justify-content-between">
                      <div className="p-2">{user.address_watch_list || '0'} Address Alert(s)</div>
                      <div className="p-2">
                        <a href="myaddress">
                          <i className="fa fa-search-plus"></i>
                        </a>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="py-2">Transaction Notes :</div>
                  </td>
                  <td>
                    <div className="d-flex justify-content-between">
                      <div className="p-2">{user.trade_notes || '0'} out of 1000 available limit</div>
                      <div className="p-2">
                        <a href="mynotes_tx">
                          <i className="fa fa-search-plus"></i>
                        </a>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="py-2">Address Tags :</div>
                  </td>
                  <td>
                    <div className="d-flex justify-content-between">
                      <div className="p-2">{user.address_tags || '0'} out of 500 available limit</div>
                      <div className="p-2">
                        <a href="mynotes_address">
                          <i className="fa fa-search-plus"></i>
                        </a>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="py-2">Email Notification Limit :</div>
                  </td>
                  <td>
                    <div className="d-flex justify-content-between">
                      <div className="p-2">{user.newsletter} emails sent out 200 daily limit available</div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="py-2">Total CMP Balance :</div>
                  </td>
                  <td>
                    <div className="d-flex justify-content-between">
                      <div className="p-2">
                        {user.total_balance || '0'} CMP<span style={{display: 'none'}}> @ ($0.00)</span>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="py-2">Last Login :</div>
                  </td>
                  <td>
                    <div className="d-flex justify-content-between">
                      <div className="p-2">{user.last_login_time} (UTC)</div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </figure>
        </div>
      </div>
    </div>
  );
}

function ChangeProfilePicture({value, onChange, show, onHide}) {
  const userContext = useContext(UserContext);
  const user = userContext.user || {};
  const [file, setFile] = useState();
  const uploadFileRequest = useRequest(
    body => {
      const formData = new FormData();
      formData.append('upfile', body.upfile);
      return {
        url: '/blockBrowser/user/userInfo/uploadFile',
        method: 'post',
        headers: {
          // 'Content-Type': 'multipart/form-data',
          Authorization: user.token,
        },
        body: formData,
      };
    },
    {manual: true, formatResult: r => r},
  );
  const handleContinue = () => {
    if (file) {
      // 上传文件
      uploadFileRequest.run({upfile: file}).then(res => {
        onChange(res.data);
        onHide();
      });
    } else {
      onHide();
    }
  };

  const handleUploadFile = e => {
    setFile(e.target.files[0]);
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton className="p-2">
        <Modal.Title>Change your Public Profile Picture type</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group" style={{marginLeft: '-15px'}}>
          <div
            className="custom-control custom-radio form-check-inline"
            style={{marginBottom: '15px'}}
            onClick={e => {
              onChange(1);
            }}
          >
            <input
              type="radio"
              value="RadioButton5"
              checked={value === 1 ? 'checked' : ''}
              onChange={e => {
                console.log(e);
              }}
            />
            <label className="radio" htmlFor="ContentPlaceHolder1_RadioButton5">
              &nbsp;
              <span>
                <span className="btn btn-icon btn-md btn-soft-success ml-1 mr-1 rounded-circle" data-toggle="tooltip" title="" data-original-title="Your Avatar Profile">
                  <span className="btn-icon__inner">N</span>
                </span>
              </span>
              &nbsp;System assigned profile picture&nbsp;
            </label>
          </div>
          <div
            className="custom-control custom-radio form-check-inline"
            style={{marginBottom: '16px'}}
            onClick={e => {
              onChange(2);
            }}
          >
            <input
              type="radio"
              value="RadioButton6"
              checked={value === 2 ? 'checked' : ''}
              onChange={e => {
                console.log(e);
              }}
            />
            <label className="radio" htmlFor="ContentPlaceHolder1_RadioButton6">
              &nbsp;
              <span>
                <img
                  className="u-avatar rounded-circle ml-1 mr-1"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAACACAYAAADDPmHLAAADxElEQVR4Xu3dMW5UMRSF4Tc1DT3sIFkCBV1ExSpSsYBI7IAFkGVESkXLYqBFaahBVPTvs3Qx+emPxxyf999rj/Pmcnt99euAf+9efQD1cXz5/pn0n16+J72K754eaYhp/y4FgNbvKAARgBIUASoBFCAtoZUAsv+oBEwjrCbQmugIEAHaBkoG2gW0C5D8HNMltBJAy1cTOJ7gmsCaQHyGTV4PUA9ACaoH6CSQAtRJYN8GUoDaBZB9/8Eu4OHmnu4DKILQ/2cv1x7iUgD2zlAB2Hv9ePYFgC3ce4ACsPf68ewLAFu49wAFYO/149kXALZw7wEKwN7rx7MvAGzh3gMUgL3Xj2dfANjCvQcoAHuvH8++ALCFew9QAPZeP559AWAL9x5gPABq33O/T6ALqP7zfQCdQAGwF2yo/wVAHUR9BMBbwej/uLwAFIDREFYCRu0/+E/rdPoFQB1EfSWgEoARMnkEMP9YHQEiAIdIBogA4t4CbQSIAAtidH6ICHDeuyXKCBABlgTp7CAR4Kxzi3QRIAIsitK5YfgFEec+9q9q+gnQ+at++uvwAqAriPoCgG8ZQ//H5QWgAIyGsBIwar//ZpJOvwCog6ivBFQCMEImjwDmH6sjQATgEMkAEUDcW6CNABFgQYzODxEBznu3RBkBIsCSIJ0dJAKcdW6RLgJEgEVROjdMBDjn2zLVOAF+vPlIvxfw9cVrMkMNeO6/Gvb25zfy/1IAyL/xH48uAM/8N4MKQAEghFUCyL75H42KABGAIhwByL4IcLQNfKQI6bX4SkAlgAJYCSD7KgGVgKdKAD1DHQXbm0LrAeoB6AGsByD76gHqAeoB+jpYIDJ+DnB7fUX3AfT7eD1I0iZIFu+Pdnr+d0ggvhFUAIyAGuACgDdiIkAlgDJQCcB9+LSBtPr1AMdRD1APQA9RBLBbvTWBNYH0ALYNJPs6B6gHwD+M6Rxg2EAEQCeB7QLaBdBD1C6gXQAFSGsofXgHQR0ETROsc4DOAQhifCVME6gXIuh//w+Ipy/FFoDhEBSA3hFEEdRteAQg+10cASIApSgCkH3z4ggQASiFEYDsmxdHgAhAKYwAZN+8OAJEAEphBCD75sURIAJQCiMA2TcvjgARgFIYAci+efE4AR5u7un9ANMW7n4lbNq/SwGwJdArYfbpri4A6GEBQANVXgkwByOA+cd/GYQfz/ICgBZWAtBAlVcCzMEIYP5VAtA/lkcAszACmH8RAP1jeQQwCyOA+RcB0D+WRwCzMAKYfxEA/WN5BDALI4D5tz0BfgN+qfH+aHzz4QAAAABJRU5ErkJggg=="
                />
              </span>
              &nbsp;Blockies Type Profile Picture&nbsp;
            </label>
          </div>
          <div className="custom-control custom-radio form-check-inline">
            <span className="aspNetDisabled">
              <input type="radio" value="RadioButton7" disabled="disabled" />
            </span>
            <label className="radio" htmlFor="ContentPlaceHolder1_RadioButton7">
              &nbsp;
              <span style={{position: 'relative'}}>
                <span
                  className="customImgRemoveBtn"
                  data-toggle="modal"
                  data-target="#removePictureModal"
                  style={{
                    position: 'absolute',
                    width: '20px',
                    height: '20px',
                    left: '30px',
                    top: '-15px',
                    borderRadius: '15px',
                    border: '1px solid lightgrey',
                    textAlign: 'center',
                    background: '#fff',
                    WebkitBoxShadow: '1px 4px 5px 0px rgba(0, 0, 0, 0.43)',
                    boxShadow: '1px 4px 5px 0px rgba(0, 0, 0, 0.43)',
                    visibility: 'hidden',
                    color: '#3e444a',
                  }}
                >
                  <strong>X</strong>
                </span>
                <span></span>
                <span>
                  &nbsp;Custom Pic&nbsp;<i className="fa fa-spin fa-spinner fa-1x fa-pulse mr-1" style={{display: 'none'}}></i>
                  <input type="file" title="Recommended size of 128 x128 px and less than 100kb" onChange={handleUploadFile} accept=".png,.jpg,.svg" />
                </span>
              </span>
            </label>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button data-dismiss="modal" className="btn btn-sm btn-secondary btn-xs" type="button" causesvalidation="False" onClick={onHide}>
          Cancel
        </button>
        <input type="button" value="Continue" className="btn btn-sm btn-primary btn-xs" onClick={handleContinue} />
      </Modal.Footer>
    </Modal>
  );
}

function ProfileTab() {
  const userContext = useContext(UserContext);
  const user = userContext.user || {};
  const [showChangeProfilePicture, setShowChangeProfilePicture] = useState(false);
  const [errors, setErrors] = useState({});
  const [editResult, setEditResult] = useState({});
  const [form, setForm] = useState({
    email_address: user.email,
    old_password: '',
    new_password: '',
    confirm_password: '',
    profile_info: {
      public_profile_name: user.profile_info.public_profile_info,
      public_profile_picture: user.profile_info.public_profile_picture,
      public_profile_picture_type: 1, // 1: 系统，2: 锁定， 3: 上传
      public_profile_bio: user.profile_info.public_profile_bio,
      profile_website: user.profile_info.profile_website,
    },
    newsletter: user.newsletter, // 0: 未订阅, 1: 已订阅, 2: 已取消
    newsletter_cancel: 1,
    newsletter_cancel_content: '',
  });
  const [newsletter, setNewsletter] = useState(user.newsletter);
  const userEditRequest = useRequest(
    body => ({
      url: '/blockBrowser/user/userInfo/userEdit',
      method: 'post',
      headers: {
        Authorization: user.token,
      },
      body: JSON.stringify(body),
    }),
    {manual: true, formatResult: r => r},
  );
  const hideChangeProfilePicture = () => {
    setShowChangeProfilePicture(false);
  };

  const handleChangeProfilePicture = e => {
    if (typeof e === 'number') {
      handleProfileInfoField('public_profile_picture_type')({target: {value: e}});
    } else {
      handleProfileInfoField('public_profile_picture_type')({target: {value: 3}});
      handleProfileInfoField('public_profile_picture')({target: {value: e}});
    }
  };

  const handleToggleNewsletter = type => e => {
    setForm({...form, newsletter_cancel: type});
  };

  const handleField = field => e => {
    setForm({...form, [field]: e.target.value});

    if (e.target.value) {
      setErrors({...errors, [field]: undefined});
    }
  };

  const handleBlur = field => e => {};

  const handleProfileInfoField = field => e => {
    setForm({
      ...form,
      profile_info: {
        ...form.profile_info,
        [field]: e.target.value,
      },
    });

    if (e.target.value) {
      setErrors({...errors, [field]: undefined});
    }
  };

  const handleProfileInfoBlur = field => e => {};

  const handleSubmit = () => {
    console.log(form);
    userEditRequest.run(form).then(res => {
      setEditResult(res);
    });
  };

  const handleCancel = () => {};

  const handleDelete = e => {
    e.preventDefault();
  };

  return (
    <div className="tab-pane fade active show" role="tabpanel" aria-labelledby="profile-tab">
      <span></span>
      {editResult.code === 0 ? (
        <div className="alert alert-success">
          <strong>Status:</strong> Account information updated successfully
        </div>
      ) : undefined}

      <div className="py-2">
        <div className="w-md-80 mx-md-auto">
          <figure className="mb-7 ie-data-report">
            <form method="post" className="js-validate mx-md-auto" autoComplete="off" noValidate="noValidate">
              <h3 className="small text-muted mt-3">YOUR USER LOGIN SETTINGS</h3>
              <div className="form-group row mt-4" style={{marginBottom: '7px'}}>
                <label className="col-sm-4 text-nowrap" htmlFor="Username">
                  <b>Username</b>
                </label>
                <div className="col-sm-8">{user.user_name}</div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 text-nowrap align-self-center mb-sm-0" htmlFor="Emailaddress">
                  Email address
                </label>
                <div
                  className="js-form-message input-group input-group-sm col-sm-8"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  data-title="You will need to reconfirm your email address if changed"
                  data-trigger="focus"
                  data-original-title=""
                  title=""
                >
                  <input
                    value={form.email_address}
                    placeholder="Email address"
                    className="form-control"
                    type="email"
                    required=""
                    data-msg="Please enter your valid email address"
                    data-error-class="u-has-error"
                    data-success-class="u-has-success"
                    onChange={handleField('email_address')}
                    onBlur={handleBlur('email_address')}
                  />
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <span className="fa fa-lg fa-envelope"></span>
                    </span>
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 text-nowrap align-self-center mb-sm-0" htmlFor="Oldpassword">
                  Enter OLD password
                </label>
                <div
                  className="input-group input-group-sm col-sm-8"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  data-title="Leave the password box empty if no changes"
                  data-trigger="focus"
                  data-original-title=""
                  title=""
                >
                  <input type="password" placeholder="Password" className="form-control" value={form.old_password} onChange={handleField('old_password')} onBlur={handleBlur('old_password')} />
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <span className="fa fa-lg fa-lock"></span>
                    </span>
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 text-nowrap align-self-center mb-sm-0" htmlFor="Newpassword">
                  Enter NEW password
                </label>
                <div
                  className="input-group input-group-sm col-sm-8"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  data-title="Leave the password box empty if no changes"
                  data-trigger="focus"
                  data-original-title=""
                  title=""
                >
                  <input type="password" placeholder="Password" className="form-control" value={form.new_password} onChange={handleField('new_password')} onBlur={handleBlur('new_password')} />
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <span className="fa fa-lg fa-lock"></span>
                    </span>
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 text-nowrap align-self-center" htmlFor="Confirmnewpassword">
                  Re-Confirm password
                  <span style={{color: 'red', display: 'none'}}>**</span>
                  <span style={{color: 'red', fontSize: 'smaller', display: 'none'}}>
                    <br />
                    Passwords do not match
                  </span>
                </label>
                <div
                  className="input-group input-group-sm col-sm-8 mb-4"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  data-title="Re-enter your password confirmation if you changed your password above"
                  data-trigger="focus"
                  data-original-title=""
                  title=""
                >
                  <input
                    type="password"
                    placeholder="Confirm password"
                    className="form-control"
                    value={form.confirm_password}
                    onChange={handleField('confirm_password')}
                    onBlur={handleBlur('confirm_password')}
                  />
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <span className="fa fa-lg fa-lock"></span>
                    </span>
                  </div>
                </div>
              </div>
              <hr className="mt-3 mb-4" />
              <h3 className="small text-muted">
                CaduceusInfo PROFILE INFO
                <span className="float-right u-label u-label--secondary font-size-1 rounded py-1 px-2" style={{marginTop: '-4px'}} title="Profile Status/Level">
                  Status: User
                </span>
              </h3>
              <div className="form-group row mt-4">
                <label className="col-sm-4 text-nowrap align-self-center mb-sm-0" htmlFor="">
                  <b
                    data-toggle="tooltip"
                    data-html="true"
                    data-title="<p className='text-white text-left mb-0'>Your PUBLIC Profile information can be viewed publicly.<br>Please do not enter sensitive information like your private keys here.</p>"
                    data-original-title=""
                    title=""
                  >
                    Public Profile Name <i className="text-success far fa-question-circle"></i>
                  </b>
                  <span style={{color: 'red', fontSize: 'smaller', display: 'none'}}>
                    <br />
                    *Min of 7 characters, no spaces
                  </span>
                </label>
                <div
                  className="input-group input-group-sm col-sm-8"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  data-title="This is your public user profile name (Alphanumeric with minimum of 7 and not more than 35 characters)."
                  data-trigger="focus"
                  data-original-title=""
                  title=""
                >
                  <input
                    maxLength="35"
                    type="text"
                    autoComplete="new-password"
                    placeholder="Public user profile name"
                    className="form-control"
                    value={form.profile_info.public_profile_name}
                    onChange={handleProfileInfoField('public_profile_name')}
                    onBlur={handleProfileInfoBlur('public_profile_name')}
                  />
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <span className="fas fa-lg fa-user-alt"></span>
                    </span>
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 text-nowrap align-self-center mb-sm-0" htmlFor="">
                  Public Profile Picture
                </label>
                <div className="input-group input-group-sm col-sm-8">
                  <span>
                    <img
                      className="u-avatar rounded-circle ml-1 mr-1"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAACACAYAAADDPmHLAAADxElEQVR4Xu3dMW5UMRSF4Tc1DT3sIFkCBV1ExSpSsYBI7IAFkGVESkXLYqBFaahBVPTvs3Qx+emPxxyf999rj/Pmcnt99euAf+9efQD1cXz5/pn0n16+J72K754eaYhp/y4FgNbvKAARgBIUASoBFCAtoZUAsv+oBEwjrCbQmugIEAHaBkoG2gW0C5D8HNMltBJAy1cTOJ7gmsCaQHyGTV4PUA9ACaoH6CSQAtRJYN8GUoDaBZB9/8Eu4OHmnu4DKILQ/2cv1x7iUgD2zlAB2Hv9ePYFgC3ce4ACsPf68ewLAFu49wAFYO/149kXALZw7wEKwN7rx7MvAGzh3gMUgL3Xj2dfANjCvQcoAHuvH8++ALCFew9QAPZeP559AWAL9x5gPABq33O/T6ALqP7zfQCdQAGwF2yo/wVAHUR9BMBbwej/uLwAFIDREFYCRu0/+E/rdPoFQB1EfSWgEoARMnkEMP9YHQEiAIdIBogA4t4CbQSIAAtidH6ICHDeuyXKCBABlgTp7CAR4Kxzi3QRIAIsitK5YfgFEec+9q9q+gnQ+at++uvwAqAriPoCgG8ZQ//H5QWgAIyGsBIwar//ZpJOvwCog6ivBFQCMEImjwDmH6sjQATgEMkAEUDcW6CNABFgQYzODxEBznu3RBkBIsCSIJ0dJAKcdW6RLgJEgEVROjdMBDjn2zLVOAF+vPlIvxfw9cVrMkMNeO6/Gvb25zfy/1IAyL/xH48uAM/8N4MKQAEghFUCyL75H42KABGAIhwByL4IcLQNfKQI6bX4SkAlgAJYCSD7KgGVgKdKAD1DHQXbm0LrAeoB6AGsByD76gHqAeoB+jpYIDJ+DnB7fUX3AfT7eD1I0iZIFu+Pdnr+d0ggvhFUAIyAGuACgDdiIkAlgDJQCcB9+LSBtPr1AMdRD1APQA9RBLBbvTWBNYH0ALYNJPs6B6gHwD+M6Rxg2EAEQCeB7QLaBdBD1C6gXQAFSGsofXgHQR0ETROsc4DOAQhifCVME6gXIuh//w+Ipy/FFoDhEBSA3hFEEdRteAQg+10cASIApSgCkH3z4ggQASiFEYDsmxdHgAhAKYwAZN+8OAJEAEphBCD75sURIAJQCiMA2TcvjgARgFIYAci+efE4AR5u7un9ANMW7n4lbNq/SwGwJdArYfbpri4A6GEBQANVXgkwByOA+cd/GYQfz/ICgBZWAtBAlVcCzMEIYP5VAtA/lkcAszACmH8RAP1jeQQwCyOA+RcB0D+WRwCzMAKYfxEA/WN5BDALI4D5tz0BfgN+qfH+aHzz4QAAAABJRU5ErkJggg=="
                    />
                  </span>
                  <div style={{marginTop: '4px'}}>
                    <a
                      href="#"
                      data-toggle="modal"
                      rel="tooltip"
                      data-target="#myModal"
                      title=""
                      className="ml-1 btn btn-xss btn-primary"
                      data-original-title="Select your Profile Picture Type"
                      onClick={e => {
                        e.preventDefault();
                        setShowChangeProfilePicture(true);
                      }}
                    >
                      <span>Select</span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 text-nowrap align-self-center mb-sm-0" htmlFor="Oldpassword">
                  Public Profile Bio
                </label>
                <div
                  className="input-group input-group-sm col-sm-8"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  data-title="Tell us more about yourself (Up to 155 characters)"
                  data-trigger="focus"
                  data-original-title=""
                  title=""
                >
                  <input
                    maxLength="155"
                    type="text"
                    placeholder="Public bio profile"
                    className="form-control"
                    value={form.profile_info.public_profile_bio}
                    onChange={handleProfileInfoField('public_profile_bio')}
                    onBlur={handleProfileInfoBlur('public_profile_bio')}
                  />
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <span className="fa-lg far fa-book-user"></span>
                    </span>
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 text-nowrap align-self-center" htmlFor="Oldpassword">
                  Profile Website
                  <span style={{color: 'red', fontSize: 'smaller', display: 'none'}}>
                    <br />
                    *Incorrect format or missing https://
                  </span>
                </label>
                <div
                  className="input-group input-group-sm col-sm-8 mb-3"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  data-html="true"
                  data-title="Your website URL including the https or https <br>(i.e https://yourdomain.com )"
                  data-trigger="focus"
                  data-original-title=""
                  title=""
                >
                  <input
                    maxLength="155"
                    type="text"
                    placeholder="https://yourwebsite.com"
                    className="form-control"
                    value={form.profile_info.profile_website}
                    onChange={handleProfileInfoField('profile_website')}
                    onBlur={handleProfileInfoBlur('profile_website')}
                  />
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <span className="fa-lg fas fa-globe"></span>
                    </span>
                  </div>
                </div>
              </div>
              <hr className="mt-3 mb-4" />
              <h3 className="small text-muted">MONTHLY NEWSLETTER SUBSCRIPTION AND GIVEAWAYS</h3>
              <div className="form-group row mt-4">
                <label className="col-sm-4" htmlFor="Description">
                  Newsletter
                </label>
                <div className="col-sm-8">
                  <div
                    className="custom-control custom-checkbox d-flex align-items-center"
                    onClick={() => {
                      setNewsletter(newsletter === 1 ? 2 : 1);
                    }}
                  >
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      data-toggle="tooltip"
                      title=""
                      checked={newsletter === 1 ? 'checked' : ''}
                      data-original-title="Subscribe for Newsletter"
                      onChange={e => {
                        console.log(e);
                      }}
                    />
                    <label className="custom-control-label" htmlFor="ContentPlaceHolder1_chkNewsletter">
                      (click to Subscribe for Newsletter)
                    </label>
                  </div>
                </div>
              </div>
              <div className="form-group row" style={{display: newsletter === 2 ? undefined : 'none'}}>
                <div className="col-sm-4"></div>
                <div className="col-sm-8">
                  <div className="mb-2">
                    <p>Please let us know why you unsubscribed:</p>
                  </div>
                  <div className="custom-control custom-radio d-flex align-items-center mb-1" onClick={handleToggleNewsletter(1)}>
                    <input
                      value="RadioButton0"
                      type="radio"
                      className="custom-control-input"
                      checked={form.newsletter_cancel === 1 ? 'checked' : ''}
                      onChange={e => {
                        console.log(e);
                      }}
                    />
                    <label className="custom-control-label" htmlFor="ContentPlaceHolder1_RadioButton0">
                      I no longer want to receive these emails
                    </label>
                  </div>
                  <div className="custom-control custom-radio d-flex align-items-center mb-1" onClick={handleToggleNewsletter(2)}>
                    <input
                      value="RadioButton1"
                      type="radio"
                      className="custom-control-input"
                      checked={form.newsletter_cancel === 2 ? 'checked' : ''}
                      onChange={e => {
                        console.log(e);
                      }}
                    />
                    <label className="custom-control-label" htmlFor="ContentPlaceHolder1_RadioButton1">
                      I never signed up for this mailing list
                    </label>
                  </div>
                  <div className="custom-control custom-radio d-flex align-items-center mb-1" onClick={handleToggleNewsletter(3)}>
                    <input
                      value="RadioButton2"
                      type="radio"
                      className="custom-control-input"
                      checked={form.newsletter_cancel === 3 ? 'checked' : ''}
                      onChange={e => {
                        console.log(e);
                      }}
                    />
                    <label className="custom-control-label" htmlFor="ContentPlaceHolder1_RadioButton2">
                      The emails are inappropriate
                    </label>
                  </div>
                  <div className="custom-control custom-radio d-flex align-items-center mb-1" onClick={handleToggleNewsletter(4)}>
                    <input
                      value="RadioButton3"
                      type="radio"
                      className="custom-control-input"
                      checked={form.newsletter_cancel === 4 ? 'checked' : ''}
                      onChange={e => {
                        console.log(e);
                      }}
                    />
                    <label className="custom-control-label" htmlFor="ContentPlaceHolder1_RadioButton3">
                      The emails are spam and should be reported
                    </label>
                  </div>
                  <div className="custom-control custom-radio d-flex align-items-center mb-1" onClick={handleToggleNewsletter(5)}>
                    <input
                      value="RadioButton4"
                      type="radio"
                      className="custom-control-input"
                      checked={form.newsletter_cancel === 5 ? 'checked' : ''}
                      onChange={e => {
                        console.log(e);
                      }}
                    />
                    <label className="custom-control-label" htmlFor="ContentPlaceHolder1_RadioButton4">
                      Other
                    </label>
                  </div>
                </div>
              </div>
              <hr className="mt-3 mb-4" />
              <br />
              <div className="d-flex justify-content-start mb-2">
                <div className="mb-2">
                  <input type="button" onClick={handleSubmit} value="Save Changes" className="btn btn-sm btn-primary btn-xs mr-2" />
                </div>

                <div className="mb-2" style={{visibility: 'hidden'}}>
                  <input type="button" onClick={handleCancel} value="Cancel" className="btn btn-sm btn-secondary btn-xs mr-2 cancel" />
                </div>

                <div className="ml-auto" style={{visibility: 'hidden'}}>
                  <a href="#" className="btn btn-danger btn-xs" onClick={handleDelete}>
                    <i className="far fa-trash-alt" data-toggle="tooltip" title="" data-original-title="Delete Account"></i>&nbsp; Delete Account
                  </a>
                </div>
              </div>
            </form>
          </figure>
        </div>
      </div>
      <ChangeProfilePicture show={showChangeProfilePicture} user={user} onHide={hideChangeProfilePicture} value={form.profile_info.public_profile_picture_type} onChange={handleChangeProfilePicture} />
    </div>
  );
}
export default function MyAccount() {
  const userContext = useContext(UserContext);
  const user = userContext.user || {};

  const location = useLocation();
  const [currentTab, setCurrentTab] = useState('overview');

  if (!user.token) {
    return (window.location.href = '/login');
  }

  return (
    <main role="main">
      <div className="container space-1">
        <div className="row">
          <MySideBar value="myaccount" />
          <div className="col-md-9">
            <div className="card">
              <div className="card-header d-md-flex justify-content-between align-items-center p-0">
                <ul className="nav nav-custom nav-borderless" role="tablist">
                  <li
                    className="nav-item"
                    onClick={() => {
                      setCurrentTab('overview');
                    }}
                  >
                    <a className={`nav-link ${currentTab === 'overview' ? 'active' : ''}`} data-toggle="tab" href="#overview" role="tab" aria-controls="overview" aria-selected="true">
                      Overview
                    </a>
                  </li>
                  <li
                    className="nav-item"
                    onClick={() => {
                      setCurrentTab('profile');
                    }}
                  >
                    <a className={`nav-link ${currentTab === 'profile' ? 'active' : ''}`} data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">
                      Account Settings &amp; Profile
                    </a>
                  </li>
                </ul>
              </div>
              <div className="card-body">
                <div className="tab-content">
                  {currentTab === 'overview' ? (
                    <OverviewTab
                      onChange={e => {
                        if (e.action === 'switchTab') {
                          setCurrentTab('profile');
                        }
                      }}
                    />
                  ) : undefined}

                  {currentTab === 'profile' ? <ProfileTab /> : undefined}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

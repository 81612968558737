import { useEffect, useState } from 'react';
import { useRequest } from 'ahooks';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import qs from 'qs';

import Loading from '../../components/Loading';
import TimeTD from '../../components/TimeTD';
import Pager from '../../components/Pager';

export default function Tokentxns({ address, overview }) {
  const location = useLocation();
  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [state, setState] = useState({
    body: {
      start: query.p || '1',
      length: '25',
    },
  });
  const internalTradeListRequest = useRequest({
    url: '/blockBrowser/blockChain/contractInternalTrade/contractTradeTokenList',
    method: 'post',
    body: JSON.stringify({
      start: state.body.start,
      length: '25',
      field: 'address',
      value: address,
    }),
  });

  if (internalTradeListRequest.loading) {
    return <Loading />;
  }
  const data = internalTradeListRequest.data?.contract_internal_trade_list || [];
  const contract_internal_trade_count = internalTradeListRequest.data?.contract_internal_trade_count || 0;
  const counts = internalTradeListRequest.data?.counts;
  const path = window.location.pathname;
  const totalPage = Math.ceil(Number(counts) / state.body.length);

  return (
    <div className="tab-pane fade active show" role="tabpanel" aria-labelledby="events-tab">
      <div className="d-md-flex justify-content-between align-items-center mb-4">
        <p className="mb-2 mb-lg-0">
          <i className="fa fa-spin fa-spinner fa-1x fa-pulse" style={{ display: 'none', marginTop: '4px', marginRight: '4px' }}>
            &nbsp;
          </i>
          <i className="fas fa-sort-amount-down" data-toggle="tooltip" data-placement="bottom" title="Oldest First"></i>&nbsp;Latest {contract_internal_trade_count} CRC-20 Token Transfer Events
        </p>
        <div className="d-flex flex-wrap flex-xl-nowrap text-nowrap align-items-center ml-auto">
          {
            parseInt(contract_internal_trade_count) < parseInt(counts) ?
              <Pager path={`${path}`} hash={'#tokentxns'} current={state.body.start} total={totalPage} /> : undefined
          }
        </div>
        <nav className="mb-4 mb-md-0" aria-label="page navigation"></nav>
      </div>
      <div className="table-responsive">
        <table className="table table-md-text-normal table-hover">
          <thead className="thead-light">
            <tr>
              <th scope="col" width="1">
                Txn Hash
              </th>
              <th scope="col">
                <a href="#" title="" data-toggle-commonjs="tooltip" data-original-title="Click to show Datetime Format">
                  Age
                </a>
              </th>
              <th scope="col">From</th>
              <th scope="col" width="30"></th>
              <th scope="col">To</th>
              <th scope="col">Value</th>
              <th scope="col">Token</th>
            </tr>
          </thead>
          <tbody>
            {data.map(item => {
              const time = moment(item.create_time).startOf('minute').fromNow();
              return (
                <tr key={item.order_no}>
                  <td>
                    <span className="hash-tag text-truncate">
                      <span className="text-danger" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Error in Txn: execution reverted">
                        <strong>
                          <i className={item.status == 0 || item.status == 2 ? "fa fa-exclamation-circle" : ""}></i>
                        </strong>
                      </span>
                      <a href={`/tx/${item.parent_trade_hash}`} target="_parent">
                        {item.parent_trade_hash}
                      </a>
                    </span>
                  </td>
                  <TimeTD time={item.trade_time} interval={item.trade_time_interval} />
                  <td style={{ whiteSpace: 'nowrap' }}>
                    <a
                      className="hash-tag text-truncate"
                      style={{ color: item.trade_from_type === 2 ? '#000' : '' }}
                      href={item.trade_from_type === 2 ? undefined : `/address/${item.trade_from}`}
                      target="_parent"
                      data-boundary="viewport"
                      data-html="true"
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title=""
                      data-original-title={item.trade_from}
                    >
                      {item.trade_from}
                    </a>
                  </td>
                  <td>
                    <span className={`u-label u-label--xs u-label--${item.trade_from_type === 2 ? 'warning' : 'success'} color-strong text-uppercase text-center w-100 rounded text-nowrap`}>
                      &nbsp;{item.trade_from_type === 2 ? 'OUT' : 'IN'}&nbsp;
                    </span>
                  </td>
                  <td style={{ whiteSpace: 'nowrap' }}>
                    <a className="hash-tag text-truncate"
                      style={{ color: item.trade_from_type === 2 ? '' : '#000' }}
                      href={item.trade_from_type === 2 ? `/address/${item.trade_to}` : undefined}
                      data-boundary="viewport"
                      data-html="true"
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title="" data-original-title={item.trade_to}>
                      {item.trade_to}
                    </a>
                  </td>
                  <td>{item.value}</td>
                  <td>
                    <a href={`/token/${item.token_address}?a=${address}`} target="_parent">
                      {/* <a href={`/token/0x34005a5fab20cefb34515a02a74054da11912398?a=${address}`} target="_parent"> */}
                      <img src={item.token_image ? item.token_image : '/images/main/empty-token.png'} width="13px" />
                      {item.token_name}
                    </a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {/* <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center text-secondary"></div>
        <div className="small text-muted">
          <span className="float-right" data-toggle="tooltip" title="Export records in CSV format">
            <font size="1">
              [ Download
              <a href={`/exportData?type=addresstokentxns&a=${address}`} className="mx-1" target="_blank">
                <b> CSV Export</b>
              </a>
              <span className="fas fa-download text-secondary"></span> ]
            </font>
          </span>
        </div>
      </div> */}
    </div>
  );
}
